/**
 *
 * Blueprint
 *
 */

import React, { useCallback, useEffect, useState } from 'react';

import { HStack, Stack, useDisclosure, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';

import { Card } from 'components/DataDisplay';
import { WithSpinner } from 'components/DataDisplay/Spinner/WithSpinner';
import {
  BackIconButton,
  buttonStyles,
  PrimaryButton,
  PrimaryIconButton,
} from 'components/DataEntry/Button';
import { ConfigurationIcon, TrashIcon } from 'components/Icons';
import { usePageContentContext } from 'components/Layout';
import { DeleteConfirmationModal } from 'components/Overlay/Modal/DeleteConfirmationModal';
import { Configure } from 'containers/PolicyHub/Blueprints/Components/Utils/Configure';
import { useBlueprint } from 'containers/PolicyHub/Blueprints/hooks/context';
import { selectBlueprints } from 'containers/PolicyHub/Blueprints/selectors';
import { actions } from 'containers/PolicyHub/Blueprints/slice';

import { BlueprintDetails } from './Details/BlueprintDetails';

export const Blueprint = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams<{ id: string }>();
  const { contentHeight } = usePageContentContext();
  const { blueprint, blueprintAction } = useSelector(selectBlueprints);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const { showPolicies, setShowPolicies } = useBlueprint();
  const addPolicy = useDisclosure();

  const getBlueprint = useCallback(() => {
    if (!!params.id) {
      dispatch(
        actions.getBlueprintByID({
          q: { blueprintId: params.id },
        }),
      );
      dispatch(actions.getBlueprintSummary({ q: { blueprintId: params.id } }));
      dispatch(
        actions.getAttachedWorkflows({
          q: { policyId: params?.id },
        }),
      );
    }
  }, [dispatch, params]);

  useDeepCompareEffect(() => {
    getBlueprint();
  }, [getBlueprint]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetBlueprintForm());
    };
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(
      actions.deleteBlueprint({
        q: { blueprintId: blueprint.data?.id },
        onSuccess: () => {
          deleteDisclosure.onClose();
          navigate('/policy-hub');
        },
      }),
    );
  };

  const configureStyles = showPolicies ? {} : { bg: 'primary', color: 'white' };

  return (
    <Stack h={contentHeight - 6 + 'px'} w={'full'} spacing={5}>
      <Card styles={{ card: { w: 'full', h: 16, py: 3 } }}>
        <HStack justify="space-between" w="full" h="full">
          <HStack color="primary">
            <BackIconButton
              onClick={() =>
                addPolicy.isOpen
                  ? addPolicy.onClose?.()
                  : showPolicies
                  ? setShowPolicies(false)
                  : navigate('/policy-hub/blueprints')
              }
            />
            <Box
              onClick={() => navigate('/policy-hub/blueprints')}
              cursor="pointer"
            >
              Policy Blueprint
            </Box>
            {showPolicies && !blueprint.isLoading && <Box>/</Box>}
            <Box
              onClick={() => {
                setShowPolicies(false);
                addPolicy.onClose();
              }}
              cursor="pointer"
            >
              {showPolicies && !blueprint.isLoading ? blueprint.data.name : ''}
            </Box>
            {addPolicy.isOpen && !blueprint.isLoading && <Box>/</Box>}
            <Box onClick={() => addPolicy.onClose?.()} cursor="pointer">
              {addPolicy.isOpen && !blueprint.isLoading ? 'Policies' : ''}
            </Box>
          </HStack>

          <HStack>
            <PrimaryIconButton
              aria-label="delete"
              onClick={() => deleteDisclosure.onOpen()}
              isDisabled={blueprint.data?.read_only}
              tooltip="Delete blueprint"
              {...buttonStyles('delete')}
            >
              <TrashIcon />
            </PrimaryIconButton>
            {deleteDisclosure.isOpen && (
              <DeleteConfirmationModal
                {...deleteDisclosure}
                onConfirm={handleDelete}
                name={blueprint.data?.name}
                type={'blueprint'}
                isLoading={blueprintAction.isLoading}
              />
            )}

            <PrimaryButton
              leftIcon={<ConfigurationIcon w={4} />}
              onClick={onOpen}
              {...configureStyles}
            >
              Configure
            </PrimaryButton>

            {isOpen && (
              <Configure
                isOpen={isOpen}
                onClose={onClose}
                onSuccess={getBlueprint}
              />
            )}
          </HStack>
        </HStack>
      </Card>

      <Card>
        <WithSpinner loadStatus={{ loading: blueprint.isLoading }}>
          <Stack spacing={6} flex={1}>
            <BlueprintDetails
              getBlueprint={getBlueprint}
              addPolicy={addPolicy}
            />
          </Stack>
        </WithSpinner>
      </Card>
    </Stack>
  );
};
