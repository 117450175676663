import React, { FC, useRef } from 'react';

import { Box } from '@chakra-ui/react';

import { GraphScale } from 'components/Visualization/PixiGraph/common/GraphScale';
import { PostureGraphMenu } from 'components/Visualization/PixiGraph/components/PostureGraphMenuTooltip';
import { useGraphDnd } from 'components/Visualization/PixiGraph/hooks/useGraphDnd';
import { useGraphMenuTooltip } from 'components/Visualization/PixiGraph/hooks/useMenuTooltip';
import { usePostureMap } from 'components/Visualization/PixiGraph/maps/PostureMap/hooks/usePostureMap';

interface IAccessGraph {}

export const PostureGraph: FC<IAccessGraph> = () => {
  const ref = useRef<any>(null);
  const { app, graph, renderer } = usePostureMap({
    ref,
  });
  const graphMenu = useGraphMenuTooltip({ app, graph, renderer, ref });
  const { listeners } = useGraphDnd({ ref, app });

  return (
    <Box
      h={'full'}
      w={'full'}
      pos={'absolute'}
      onContextMenu={e => e.preventDefault()}
    >
      <div
        className="graph-canvas-v1"
        ref={ref}
        {...listeners}
        {...graphMenu.listeners}
      ></div>
      <GraphScale app={app} graph={graph} />
      <PostureGraphMenu graph={graph} app={app} renderer={renderer} />
    </Box>
  );
};
