import React, { useState, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { GraphIncreaseIcon } from 'components/Icons';
import { BarChart } from 'components/Visualization/Charts';
import { customizedXAxisTick } from 'components/Visualization/Charts/utils';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { renderCloudIcons } from 'containers/Dashboard/utils/utils';
import {
  commonVulnerabilityLineChartStyles,
  lineChartStylesVulnerabilityDashboard,
} from 'containers/Visibility/Vulnerabilities/Components/styles';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const VulnerabilitiesBySources = () => {
  const { vulnerabilitiesBySources } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const items = map(vulnerabilitiesBySources.data?.items, o => ({
      name: o.agnostic_name,
      Critical: o.vulnerability_count.critical,
      High: o.vulnerability_count.high,
      Medium: o.vulnerability_count.medium,
      Other:
        (o.vulnerability_count.low ?? 0) + (o.vulnerability_count.unknown ?? 0),
    }));

    setData(items);
  }, [vulnerabilitiesBySources.data]);

  return (
    <DashboardOverviewCard
      label="Vulnerabilities by sources"
      icon={<GraphIncreaseIcon />}
      iconBgColor="critical"
      rightHeader={renderCloudIcons(vulnerabilitiesBySources.data?.clouds)}
      content={
        <Box w="full" h="full">
          <BarChart
            data={data}
            isLoading={vulnerabilitiesBySources.isLoading}
            xField="name"
            yField={['Critical', 'High', 'Medium', 'Other']}
            colorMap={{
              Critical: customTheme.colors.critical,
              High: customTheme.colors.high,
              Medium: customTheme.colors.medium,
              Other: customTheme.colors.gray['200'],
            }}
            styles={{
              ...commonVulnerabilityLineChartStyles,
              barChart: {
                margin: {
                  top: 20,
                  left: 0,
                  right: 10,
                  bottom: -18,
                },
              },
              xAxis: {
                ...commonVulnerabilityLineChartStyles.xAxis,
                tick: props =>
                  customizedXAxisTick({
                    ...props,
                    sliceLength: 10,
                    style: { fontSize: 12, y: 6 },
                  }),
              },
            }}
          />
        </Box>
      }
    />
  );
};
