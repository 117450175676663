import React, { useEffect, useState } from 'react';

import { ProviderType } from '@ariksa/reporting';
import { ResourceSubCategory } from '@ariksa/reporting/api';
import { Center } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { each, includes, isEmpty, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectApp } from 'containers/App/selectors';
import { DataSecurity } from 'containers/Dashboard/DataSecurityOverview/Components';
import { NoAccountsOnboarded } from 'containers/Dashboard/Overview/Components/NoAccountsOnboarded';
import { selectDashboard } from 'containers/Dashboard/selectors';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateSlice } from 'containers/SharedState/slice';
import { useInjector } from 'utils/inject';

import { dataSecurityOverviewSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const DataSecurityOverview: React.FC = () => {
  useInjector(sliceKey, reducer, dataSecurityOverviewSaga);
  const dispatch = useDispatch();
  const { cloudAccounts, environments, user } = useSelector(selectApp);
  const { environmentId, accountId } = useAccessBoundary();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const { contentHeight } = usePageContentContext();
  const { environmentMapping } = useEnvironmentOptions();
  const [showDataSecurity, setShowDataSecurity] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { dataScannerInfo } = useSelector(selectDashboard);

  useEffect(() => {
    setEndDate(
      aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    );

    setStartDate(
      aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
    );
  }, [aiParamExtraction.data]);

  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;
    const activeEnvironment = environments.data.find(
      o => o.id === environmentId,
    );

    const cloudAccountIds = cloudAccounts.data
      .map(o => {
        if (
          Array.from(activeEnvironment?.account_ids ?? [])?.includes(o.uuid)
        ) {
          return o.cloud_account_id;
        }

        return '';
      })
      .filter(Boolean)
      .filter(n => !isNaN(parseInt(n as string))) as string[];

    const startDate_7 = aiParamExtraction.data?.start_date
      ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
      : dayjs().subtract(7, 'day').format('YYYY-MM-DD');

    /*------------------------------Data security----------------------------*/
    dispatch(
      actions.getBucketCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Data',
          resourceSubCategory: ResourceSubCategory.Buckets,
        },
      }),
    );
    dispatch(
      actions.getDBCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Data',
          resourceSubCategory: ResourceSubCategory.Databases,
        },
      }),
    );
    dispatch(
      actions.getDataSecuritySaaSCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Data',
          providerType: ProviderType.SaaS,
        },
      }),
    );
    dispatch(
      actions.getOtherDataSourcesCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Data',
          resourceSubCategory: ResourceSubCategory.Disks,
        },
      }),
    );
    dispatch(
      actions.getDataSecurityFindingsCategorySummary({
        q: {
          environmentId: environmentId as string,
          accountId,
          categoryClass: AlertCategoryClass.DataSecurity,
        },
      }),
    );
    /*Top data sources that are publicly exposed (by type)*/
    dispatch(
      actions.getPubliclyExposedDataSources({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
        },
        onSuccess: res => {
          let ids: string[] = [];
          each(res, o => each(o.resources, r => ids.push(r.uuid)));
          dispatch(
            sharedStateSlice.getRiskContext({
              q: { riskContextRequest: { uuids: ids } },
            }),
          );
        },
      }),
    );
    dispatch(
      actions.getDataSourcesHistory({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          startDate,
          endDate,
          forDataSources: true,
        },
      }),
    );
    dispatch(
      actions.getDataSourcesSummaryCount({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          startDate,
          endDate,
        },
      }),
    );
    dispatch(
      actions.getExposedAndUnencryptedDataSources({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
        },
      }),
    );

    dispatch(
      actions.getInactiveAndHasAnomalyDataSources({
        q: {
          accountId: cloudAccountIds ?? [],
          //or: user.info.org_uuid ?? '',
        },
      }),
    );
    dispatch(
      actions.getSensitiveDataBySources({
        q: { accountIds, environmentId },
      }),
    );
    dispatch(
      actions.getScannedDataSources({
        q: { accountIds, environmentId, days: 30 },
      }),
    );
  }, [
    environments,
    dispatch,
    environmentId,
    accountId,
    startDate,
    endDate,
    user.info.org_uuid,
    aiParamExtraction.data,
    cloudAccounts.data,
  ]);

  /*Data security*/
  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    if (!!dataScannerInfo.data) {
      const scanners = Object.keys(dataScannerInfo.data!);
      let show = false;
      const accounts = environmentMapping?.[environmentId!]?.account_ids;
      if (!!accountId) show = includes(scanners, accountId);
      else show = some(accounts, o => includes(scanners, o));
      setShowDataSecurity(show);
    }
  }, [
    dispatch,
    environmentId,
    accountId,
    startDate,
    endDate,
    environmentMapping,
    dataScannerInfo.data,
    dataScannerInfo.isLoading,
    dataScannerInfo.isSuccess,
  ]);

  return (
    <>
      {environments.isLoading || cloudAccounts.loading ? (
        <Center h={contentHeight + 'px'}>
          <CustomSpinner size="xl" />
        </Center>
      ) : (
        <>
          {!isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <DataSecurity isDataClassificationEnabled={showDataSecurity} />
          )}
          {isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <NoAccountsOnboarded />
          )}
        </>
      )}
    </>
  );
};
