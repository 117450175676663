import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

import { useSearchParams } from 'hooks/useSearchParams';

export const useSearchParamFunctions = () => {
  const params = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const updateValueInURL = useCallback(
    (field, value) => {
      let searchParams = '';
      const setValue = (f, v) => {
        searchParams =
          searchParams +
          (!!searchParams ? '&' : '') +
          f +
          '=' +
          (f === field ? value : v);
      };
      if (!!params.tab || field === 'tab') {
        setValue('tab', params.tab);
      }
      if (!!params.alertStatus || field === 'alertStatus') {
        setValue('alertStatus', params.alertStatus);
      }
      if (!!params.alertCategory || field === 'alertCategory') {
        setValue('alertCategory', params.alertCategory);
      }
      if (!!params.severity || field === 'severity') {
        setValue('severity', params.severity);
      }
      if (!!params.resourceType || field === 'resourceType') {
        setValue('resourceType', params.resourceType);
      }
      if (!!params.blueprintId || field === 'blueprintId') {
        setValue('blueprintId', params.blueprintId);
      }
      if (!!params.region || field === 'region') {
        setValue('region', params.region);
      }
      navigate(
        location.pathname + (!!searchParams ? '?' : '') + searchParams,
      );
    },
    [
      location.pathname,
      history,
      params.tab,
      params.alertStatus,
      params.alertCategory,
      params.severity,
      params.resourceType,
      params.blueprintId,
      params.region,
    ],
  );

  //push value to local storage and set url params
  const updateValue = useCallback(
    (field, value, localStorageFieldName) => {
      localStorage.setItem(localStorageFieldName, !!value ? value : '');
      updateValueInURL(field, !!value ? value : '');
    },
    [updateValueInURL],
  );

  return {
    updateValueInURL,
    updateValue,
  };
};
