import React from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { ContentType } from 'recharts/types/component/Tooltip';
import { customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { BarChart } from 'components/Visualization';
import {
  DashboardOverviewCard,
  DashboardOverviewCardProps,
} from 'containers/Dashboard/utils/DashboardOverviewCard';

interface Props extends Omit<DashboardOverviewCardProps, 'content'> {
  chartData: Record<string, any>[];
  isLoadingChart?: boolean;
  yFields: string[];
  xField: string;
  barChartColors: string[];
  placeholder?: string;
  labelListKey?: string;
  customTooltipContent?: ContentType<any, any>;
  onClick?(e: any, key?: string, data?: any);
  totalSensitiveData: number;
  isLoadingSensitiveData: boolean;
}

export const DataSourcesCardWithBarChart: React.FC<Props> = props => {
  const {
    chartData,
    isLoadingChart,
    yFields,
    xField,
    barChartColors,
    placeholder,
    labelListKey,
    onClick,
    customTooltipContent,
    totalSensitiveData,
    isLoadingSensitiveData,
    ...rest
  } = props;

  return (
    <DashboardOverviewCard
      content={
        placeholder && !chartData?.length ? (
          <Center color={customTheme.colors.gray['250']}>{placeholder}</Center>
        ) : (
          <Stack w="full" h="full">
            <HStack w="full" justify="flex-end" fontSize="md">
              <Box>Total Sensitive Data:</Box>
              <Box fontWeight={600}>
                {isLoadingSensitiveData ? (
                  <CustomSpinner />
                ) : (
                  formatNumber(totalSensitiveData, 1)
                )}
              </Box>
            </HStack>
            <HStack w="full" flex={1} py={3} spacing={0}>
              <Box h="full">
                <Center
                  pb={4}
                  style={{
                    writingMode: 'vertical-rl',
                    transform: 'rotate(-180deg)',
                  }}
                >
                  # of sources
                </Center>
              </Box>

              <Box w="full" h="full">
                <BarChart
                  data={chartData}
                  xField={xField}
                  yField={yFields}
                  isLoading={isLoadingChart}
                  legend={false}
                  colors={barChartColors}
                  labelListKey={labelListKey}
                  onClick={onClick}
                  customTooltipContent={customTooltipContent}
                  styles={{
                    barChart: {
                      margin: { bottom: -10, left: 0, top: 15, right: 0 },
                    },
                    yAxis: {
                      axisLine: false,
                      tickLine: false,
                      tickCount: 3,
                      style: { fontSize: 14 },
                      /*label: {
                      value: '# of sources',
                      angle: -90,
                      position: 'left',
                      offset: -10,
                      fontSize: 14,
                      fill: 'black',
                      textAnchor: 'middle',
                    },*/
                    },
                    bar: { barSize: 60 },
                    labelList: { fontSize: 14 },
                    xAxis: {
                      tick: props => {
                        const { x, y, payload } = props;
                        return (
                          <g transform={`translate(${x},${y})`}>
                            <text
                              x={0}
                              y={0}
                              dy={10}
                              textAnchor="middle"
                              fontSize={14}
                              //fontWeight={600}
                            >
                              {payload.value}
                              {/*{isString(payload.value) ? payload.value.slice(0, 6) : payload.value}*/}
                            </text>
                          </g>
                        );
                      },
                    },
                    /*xAxis: {
                  //axisLine: false,
                  //tickLine: false,
                  interval: 'preserveStartEnd',
                  ticks: [
                    chartData?.[0]?.Date,
                    chartData?.[chartData?.length - 1]?.Date,
                  ],
                },*/
                  }}
                />
              </Box>
            </HStack>
          </Stack>
        )
      }
      {...rest}
    />
  );
};
