import { AuditTrailServiceApiAuditTrailServiceResourceActionsMonthlyRequest } from '@ariksa/audittrail/api';
import {
  CloudAccountApiGetCloudAccountsRequest,
  CloudAccountApiOnboardJitAccountRequest,
  CloudAccountApiReadJitConfigRequest,
  OnboardingConfigResponse,
  PageCloudAccountGetResponse,
} from '@ariksa/cloud-account/api';
import {
  ItemApiApproveRequestRequest,
  ItemApiListAllRequestsRequest,
  PageAccessRequestGetResponse,
} from '@ariksa/jit';
import {
  CloudAccountDeleteResponse,
  ItemApiDeleteAccountRequest,
  ItemApiDenyRequestRequest,
  ItemApiEditApproversRequest,
  ItemApiGetAssumeRolePolicyRequest,
  ItemApiGetJitStatusRolesRequest,
  ItemApiListAccountsRequest,
  ItemApiToggleAutoApproveRequest,
  ItemApiUpdateJitRolesRequest,
  JitEnabledStatusGetResponse,
  PageCloudAccountInfoGetResponse,
  ResponseDenyRequestV1JitRequestsDenyRequestIdPost,
  ResponseUpdateJitRolesV1JitAccountJitRoleUpdateAccountIdPost,
  ToggleAutoApproveResponse,
} from '@ariksa/jit/api';
import { QueryContext } from 'services/utils/QueryContext';
import { takeLatestAction } from 'services/utils/takeLatestAction';

import { authOptions } from 'api/auth';
import {
  UserService,
  UserServiceListRequest,
  UserServiceListResponse,
} from 'api/auth/api.pb';
import {
  AuditTrailApiService,
  authTokenHeader,
  CloudAccountService,
  JitService,
} from 'api/services';
import { actions } from 'containers/Setup/Jit/slice';

export function* jitSaga() {
  yield takeLatestAction(actions.getCloudAccounts, doGetCloudAccounts);

  yield takeLatestAction(actions.editJitApprover, doEditJitApprover);
  yield takeLatestAction(actions.addJitApprover, doAddJitApprover);
  yield takeLatestAction(actions.getJitAccounts, doGetJitAccounts);
  yield takeLatestAction(actions.getJitApproverList, doGetJitApproverList);
  yield takeLatestAction(actions.deleteJitAccount, doDeleteJitAccount);

  yield takeLatestAction(
    actions.getJitApproveRequests,
    doGetJitApproveRequests,
  );

  yield takeLatestAction(actions.getOnboardingConfig, doGetOnboardingConfig);

  yield takeLatestAction(actions.getJitUsers, doGetJitUsers);

  yield takeLatestAction(actions.getRoles, doGetRoles);
  yield takeLatestAction(actions.updateJitRoles, doUpdateJitRoles);

  yield takeLatestAction(actions.addJitAccount, doAddJitAccount);
}

export function* doGetCloudAccounts(
  ctx: QueryContext<
    PageCloudAccountGetResponse,
    CloudAccountApiGetCloudAccountsRequest
  >,
) {
  yield ctx.fetch(() =>
    CloudAccountService.CloudAccount.getCloudAccounts(ctx.params),
  );
}

export function* doEditJitApprover(
  ctx: QueryContext<any, ItemApiEditApproversRequest>,
) {
  yield ctx.fetch(() => JitService.Jit.editApprovers(ctx.params));
}

export function* doAddJitApprover(
  ctx: QueryContext<string, ItemApiEditApproversRequest>,
) {
  yield ctx.fetch(() => JitService.Jit.editApprovers(ctx.params));
}

export function* doGetJitAccounts(
  ctx: QueryContext<
    PageCloudAccountInfoGetResponse,
    ItemApiListAccountsRequest
  >,
) {
  yield ctx.fetch(() => JitService.Jit.listAccounts(ctx.params));
}

export function* doGetJitApproverList(
  ctx: QueryContext<UserServiceListResponse, UserServiceListRequest>,
) {
  yield ctx.fetch(() => {
    return UserService.List(ctx.params, authOptions()).then(r => {
      return { data: r };
    });
  });
}

export function* doDeleteJitAccount(
  ctx: QueryContext<CloudAccountDeleteResponse, ItemApiDeleteAccountRequest>,
) {
  yield ctx.fetch(() => JitService.Jit.deleteAccount(ctx.params));
}

export function* doGetJitApproveRequests(
  ctx: QueryContext<
    PageAccessRequestGetResponse,
    ItemApiListAllRequestsRequest
  >,
) {
  yield ctx.fetch(() => JitService.Jit.listAllRequests(ctx.params), {
    cacheTime: 1000 * 30,
  });
}

export function* doGetOnboardingConfig(
  ctx: QueryContext<
    OnboardingConfigResponse,
    CloudAccountApiReadJitConfigRequest
  >,
) {
  yield ctx.fetch(() =>
    CloudAccountService.CloudAccount.readJitConfig(ctx.params),
  );
}

export function* doGetJitUsers(
  ctx: QueryContext<UserServiceListResponse, UserServiceListRequest>,
) {
  yield ctx.fetch(async () => {
    const r = await UserService.List(ctx.params, authOptions());
    return { data: r };
  });
}

export function* doGetRoles(
  ctx: QueryContext<
    JitEnabledStatusGetResponse,
    ItemApiGetJitStatusRolesRequest
  >,
) {
  yield ctx.fetch(() => JitService.Jit.getJitStatusRoles(ctx.params));
}

export function* doUpdateJitRoles(
  ctx: QueryContext<
    ResponseUpdateJitRolesV1JitAccountJitRoleUpdateAccountIdPost,
    ItemApiUpdateJitRolesRequest
  >,
) {
  yield ctx.fetch(() => JitService.Jit.updateJitRoles(ctx.params));
}

export function* doAddJitAccount(
  ctx: QueryContext<any, CloudAccountApiOnboardJitAccountRequest>,
) {
  yield ctx.fetch(() =>
    CloudAccountService.CloudAccount.onboardJitAccount(ctx.params),
  );
}
