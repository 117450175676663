import { map } from 'lodash';

import { intoOptions } from 'components/DataDisplay';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';

export const policySeverityOptions = intoOptions([
  'Critical',
  'High',
  'Medium',
  'Low',
]);

export const categoryClassOptions = map(AlertCategoryClass, o => ({
  label: o,
  value: o,
}));
