import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { VulnerabilityFiltersMenu } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/Compute/VirtualMachine/Vulnerability/VulnerabilityFiltersMenu';
import { useVulnerabilityContext } from 'containers/Visibility/Vulnerabilities/Components/hooks/context';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const VulnerabilityHeaderFilters: FC = () => {
  const { cves, vulnerabilities } = useSelector(selectVulnerabilities);
  const {
    isPatchable,
    packageType,
    packageName,
    severity,
    isExploitable,
    updateValue,
  } = useVulnerabilityContext();

  return (
    <VulnerabilityFiltersMenu
      updateValue={updateValue}
      isPatchable={isPatchable}
      packageType={packageType}
      packageName={packageName}
      severity={severity}
      isLoading={cves.isLoading || vulnerabilities.isLoading}
      isExploitable={isExploitable}
    />
  );
};
