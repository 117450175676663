import React from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  HStack,
  Link,
  Stack,
} from '@chakra-ui/react';
import { map } from 'lodash';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  QuestionCircleIcon,
  TriangleDownIcon,
  TriangleRightIcon,
} from 'components/Icons';
import { accordionStyles } from 'containers/Setup/CloudAccounts/CloudAccountWizard/Components/OnboardGcp/styles';

export const gcpPermissionList = [
  {
    label: 'Viewer',
    description: 'View most Google Cloud resources.',
  },
  {
    label: 'Service Account Token Creator',
    description:
      'Impersonate service accounts (create OAuth2 access tokens, sign blobs or JWTs, etc).',
  },
];

export const renderAccordionButton = (
  item,
  isExpanded,
  onClick,
  showHelpIcon = true,
) => (
  <AccordionButton {...accordionStyles.button} pb={isExpanded ? 0 : 2} pt={3}>
    <HStack color="primary">
      <Box boxSize={4}>
        <Center>
          {isExpanded ? <TriangleDownIcon /> : <TriangleRightIcon />}
        </Center>
      </Box>
      <Box>{item?.label}</Box>
      {showHelpIcon && (
        <Box
          boxSize={4}
          onClick={e => {
            e.stopPropagation();
            onClick(item);
          }}
        >
          <QuestionCircleIcon />
        </Box>
      )}
    </HStack>
  </AccordionButton>
);

export const renderIcon = (item, copied, setCopied) => (
  <CustomTooltip
    label={item?.copyText === copied ? 'Copied' : item?.tooltip}
    w={40}
    textAlign="center"
  >
    <Box
      boxSize={5}
      onClick={() => {
        !!item?.copyText && navigator.clipboard.writeText(item?.copyText);
        setCopied(item?.copyText);
      }}
      cursor="pointer"
    >
      <Center>{item?.icon}</Center>
    </Box>
  </CustomTooltip>
);

export const renderRequiredSteps = (steps, onClickButton, copied, setCopied) => {
  return (
    <Stack>
      <Accordion allowMultiple>
        {map(steps, o => (
          <AccordionItem {...accordionStyles.item}>
            {({ isExpanded }) => (
              <>
                {renderAccordionButton(o, isExpanded, onClickButton)}
                <AccordionPanel {...accordionStyles.panel}>
                  <Stack pl={6} py={4} spacing={4}>
                    {map(o.secondary, s => (
                      <HStack position="relative">
                        <Box
                          borderLeft="1px solid"
                          borderBottom="1px solid"
                          borderColor="gray.200"
                          h={10}
                          w={4}
                          position="absolute"
                          bottom="50%"
                        />
                        <HStack pl={4}>
                          <Box>{s.label}</Box>
                          {s?.url ? (
                            <Link href={s?.url} target="_blank">
                              {renderIcon(s, copied, setCopied)}
                            </Link>
                          ) : (
                            renderIcon(s, copied, setCopied)
                          )}
                        </HStack>
                      </HStack>
                    ))}
                  </Stack>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
      <HStack></HStack>
    </Stack>
  );
};
