import React, { FC, useEffect, useState } from 'react';

import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { forEach, includes, map, toUpper } from 'lodash';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { Card } from 'components/DataDisplay';
import { useUserDashboard } from 'containers/Findings/FindingsOverview/hooks/useUserDashboard';
import { IndividualCategoryOverview } from 'containers/Findings/FindingsOverview/Overview/IndividualCategoryOverview';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { selectSharedState } from 'containers/SharedState/selectors';

export const AlertDashboardOverview: FC = () => {
  const { alertCategories } = useSelector(selectFindingsOverview);
  const { aiParamExtraction } = useSelector(selectSharedState);
  const [categories, setCategories] = useState<Record<string, any>>({});
  const [filteredCategory, setFilteredCategory] = useState<Record<string, any>>(
    {},
  );

  const { availableWidgets } = useUserDashboard();

  useEffect(() => {
    const items: Record<string, any> = {};
    let filteredCategories = alertCategories.data;
    const categoryClass = aiParamExtraction.data?.category_class;
    if (!!categoryClass)
      filteredCategories = {
        [categoryClass]: alertCategories.data[categoryClass],
      };

    forEach(filteredCategories, (o, key) => {
      forEach(o, c => {
        if (includes(Object.keys(items), c.category))
          items[c.category] = {
            ...c,
            severity: {
              CRITICAL:
                items[c.category]?.severity?.CRITICAL + c?.severity?.CRITICAL,
              MEDIUM: items[c.category]?.severity?.MEDIUM + c?.severity?.MEDIUM,
              LOW: items[c.category]?.severity?.LOW + c?.severity?.LOW,
              HIGH: items[c.category]?.severity?.HIGH + c?.severity?.HIGH,
            },
          };
        else items[c.category] = c;
      });
    });
    setCategories(items);
  }, [alertCategories.data, aiParamExtraction.data?.category_class]);

  useEffect(() => {
    if (!!aiParamExtraction.data?.alert_category)
      setFilteredCategory(
        categories[
          toUpper(aiParamExtraction.data?.alert_category.replaceAll(' ', '_'))
        ],
      );
    else {
      setFilteredCategory({});
    }
  }, [aiParamExtraction.data, categories]);

  return (
    <Stack spacing={0}>
      {/*<CDashboard dashboardWidgets={dashboardTopCards} />*/}
      <Stack spacing={4}>
        <Card>
          <HStack w="full" spacing={6}>
            {map(availableWidgets, (o, key) => (
              <>
                <Stack w={o.w} spacing={4}>
                  <Box w="full" fontWeight={600} fontSize="md">
                    {o.name}
                  </Box>
                  <Box
                    w="full"
                    h="233px"
                    px={1}
                    py={key === 'AlertSummary' ? 2 : 1}
                  >
                    {o.components?.content}
                  </Box>
                </Stack>
                {key !== 'created_vs_resolved_issues' && (
                  <Box pt={10} pb={16} h="full">
                    <Center
                      h="full"
                      borderRight="1px solid"
                      borderColor={customTheme.colors.gray['100']}
                      my={6}
                    />
                  </Box>
                )}
              </>
            ))}
          </HStack>
        </Card>
        {!!filteredCategory?.category ? (
          <IndividualCategoryOverview
            alertCategory={filteredCategory?.category}
            severity={filteredCategory?.severity}
          />
        ) : (
          map(categories, o => (
            <IndividualCategoryOverview
              alertCategory={o.category}
              severity={o.severity}
            />
          ))
        )}
      </Stack>
    </Stack>
  );
};
