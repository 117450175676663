/**
 *
 * Add Ariksa Outpost
 *
 */

import React, { FC, useState } from 'react';

import {
  Box,
  Flex,
  ListItem,
  Stack,
  UnorderedList,
  Image,
} from '@chakra-ui/react';
import AriksaOutpostForData_1 from 'images/AriksaOutpostForData/AriksaOutpostForData_1.png';
import AriksaOutpostForData_2 from 'images/AriksaOutpostForData/AriksaOutpostForData_2.png';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { authOptions } from 'api/auth';
import { VerifyService } from 'api/auth/api.pb';
import { queryStringFromObject } from 'api/utils';
import { Form } from 'components/DataEntry/Form';
import {
  errorToast,
  PrimaryButton,
  QuestionCircleIcon,
} from 'components/index';
import { selectUser } from 'containers/App/selectors';
import { selectOutpost } from 'containers/Setup/Outpost/selectors';
import { OutpostProps } from 'containers/Setup/Outpost/types';
import { selectSharedState } from 'containers/SharedState/selectors';

import { formStyles } from '../../../../CloudAccounts/CloudAccountWizard/styles';

export const AddAriksaOutpostForDataForm: FC<OutpostProps> = props => {
  const {
    accountOptions,
    setSelectedAccount,
    selectedAccount,
    regionOptions,
  } = props;
  const navigate = useNavigate();
  const { host } = useLocation();
  const [sharedSecret, setSharedSecret] = useState('');
  const { info } = useSelector(selectUser);
  const [showHelp, setShowHelp] = useState(false);

  const { outpostToken, outpostConfig } = useSelector(selectOutpost);
  const { regions } = useSelector(selectSharedState);

  const handleGenerateToken = () => {
    VerifyService.GetClientCredentials({}, authOptions())
      .then(res => {
        setSharedSecret(res.client_secret ?? '');
      })
      .catch(err => {
        errorToast({
          title: err.message,
        });
      });
  };

  const handleSubmit = data => {
    const { region } = data;
    if (!sharedSecret) {
      errorToast({ title: 'Outpost token is not generated' });
      return;
    }

    const parameters = {
      stackName: `Ariksa-Outpost-Data-${
        selectedAccount.data?.cloud_account_id
      }-${uuidv4()}`,
      templateURL: outpostConfig.data?.cloud_template_map?.aws,
      //'https://ezl7u4ytyo-s5cwguevl7vglyy8y-hd1s8.s3.amazonaws.com/ariksa-data-scanner-cloudformation.yaml',
      param_SharedSecret: `${info.org_id}::${
        host?.split('.')[0]
      }::${sharedSecret}`,
    };

    const redirectUrl = `https://${
      region?.value
    }.console.aws.amazon.com/cloudformation/home?region=${
      region?.value
    }#/stacks/quickcreate${queryStringFromObject(parameters, {
      useSnakeCase: false,
    })}`;

    navigate('/setup/outpost');
    window.open(redirectUrl, '_blank');
  };

  const handleReset = () => {
    navigate('/setup/outpost');
  };

  const containerStyles = {
    border: '1px solid',
    borderColor: 'gray.100',
    py: 1,
    px: 3,
    w: 'full',
    borderRadius: 'md',
    overflow: 'auto',
  };

  return (
    <>
      <Form
        isLoading={outpostConfig.isLoading}
        schema={{
          account_name: {
            label: 'Deployment Account',
            type: 'react-select',
            onChange: setSelectedAccount,
            options: accountOptions,
            value: selectedAccount,
            isHelpOpen: showHelp,
            helpIcon: (
              <Box
                as={QuestionCircleIcon}
                color="primary"
                cursor="pointer"
                onClick={() => setShowHelp(!showHelp)}
              />
            ),
            helpComponent: (
              <Stack w="full" h="full">
                <Box>
                  <Image
                    src={AriksaOutpostForData_1}
                    alt={'AriksaOutpostForData_1'}
                  />
                  <Image
                    src={AriksaOutpostForData_2}
                    alt={'AriksaOutpostForData_2'}
                  />
                </Box>
              </Stack>
            ),
          },
          region: {
            type: 'react-select',
            options: regionOptions,
            defaultValue: regionOptions[0],
            label: 'Region',
          },
          role_arn_creation: {
            type: 'custom-with-form-control',
            label: 'Authentication token for Ariksa Outpost for Data',
            component: (
              <Stack w="full">
                <Box {...containerStyles} h={8} fontSize="22px">
                  {sharedSecret ? '*************************************' : ''}
                </Box>
                <Flex w="full" justify="flex-end">
                  <PrimaryButton onClick={handleGenerateToken}>
                    Generate token
                  </PrimaryButton>
                </Flex>
              </Stack>
            ),
          },
          note: {
            type: 'custom',
            component: () => (
              <Stack spacing={0} color="primary">
                <Box>NOTE:</Box>
                <Box>
                  You will be redirected to login to your AWS account to provide
                  inputs required for deploying Ariksa Outpost for Data..
                  Provide the following inputs
                </Box>
                <Box>
                  <UnorderedList>
                    <ListItem>Virtual Private Cloud (VPC)</ListItem>
                    <ListItem>Subnet</ListItem>
                    <ListItem>Scan frequency</ListItem>
                  </UnorderedList>
                </Box>
              </Stack>
            ),
          },
        }}
        buttonOptions={{
          submit: {
            name: '+ Add Outpost',
            isDisabled:
              !sharedSecret ||
              outpostToken.isLoading ||
              !outpostToken.data ||
              isEmpty(regions.data),
          },
          reset: {
            name: 'Cancel',
            isVisible: true,
            onClick: handleReset,
          },
        }}
        showHelp={showHelp}
        styles={formStyles()}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
