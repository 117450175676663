import { UserPermissionConfig } from './types';
import { UserPermission } from './UserPermission';

const hasUserRole = (userPermission: UserPermission) => {
  return userPermission.cloudResources.has('User');
};
const hasNonUserRole = (userPermission: UserPermission) => {
  return Array.from(userPermission.cloudResources).some(r => r !== 'User');
};

export const PermissionConfig: UserPermissionConfig = {
  // route permissions
  '/': () => true,
  '/home': () => true,
  '/home/ai-assistant': () => true,
  '/meta': () => true,
  '/dashboard': () => true,
  '/dashboard/overview': () => true,
  '/dashboard/data': () => true,
  '/dashboard/identity': () => true,
  '/dashboard/vulnerability': () => true,
  '/dashboard/infrastructure': () => true,

  '/findings': () => true,
  '/findings/overview': () => true,
  '/findings/alerts': () => true,
  '/findings/tickets': () => true,
  '/findings/alerts/:status/:severity': () => true,

  '/user-profile': () => true,
  '/user-profile/profile': () => true,
  '/user-profile/permissions': () => true,
  '/user-profile/timeline': () => true,
  '/user-profile/sessions': () => true,
  '/settings/:type': () => true,

  // inventory permissions
  '/inventory': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/summary': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/software': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/allowlist': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/allowlist/add': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/allowlist/edit/:uuid': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/context': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/context/add': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/inventory/context/edit/:uuid': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },

  // visibility permissions
  '/visibility': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/access': ({ cloud: { remediation } }) => {
    return false; // remediation.view('Any');
  },
  '/visibility/posture': ({ cloud: { remediation } }) => {
    return false; // remediation.view('Any');
  },
  '/visibility/security-graph': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/security-graph-next': ({ cloud: { remediation } }) => {
    return false; //remediation.view('Any');
  },
  '/visibility/identities': ({ cloud: { remediation } }) => {
    return false;
  },
  '/visibility/network': ({ cloud: { remediation } }) => {
    return false;
    // return remediation.view('Any');
  },
  '/visibility/identities/users/:entity_uuid': up => {
    const { cloud } = up;
    return cloud.remediation.view('Any') || hasUserRole(up);
  },
  '/visibility/identities/users': up => {
    const { cloud } = up;
    return false;
  },
  '/visibility/identities/users/details/:uuid': ({
    cloud: { remediation },
  }) => {
    return false;
  },
  '/visibility/identities/users/:user_id/resource-map': ({
    cloud: { remediation },
  }) => {
    return false;
  },
  '/visibility/identities/machines/:entity_uuid': ({
    cloud: { remediation },
  }) => {
    return false;
  },
  '/visibility/identities/machines/:resource_id/user-map': ({
    cloud: { remediation },
  }) => {
    return false;
  },
  '/visibility/identities/machines': ({ cloud: { remediation } }) => {
    return false;
  },
  '/visibility/resources/:entity_uuid': ({ cloud: { remediation } }) => {
    return false;
  },
  '/visibility/resources/:resource_id/user-map': ({
    cloud: { remediation },
  }) => {
    return false;
  },
  '/visibility/resources': ({ cloud: { remediation } }) => {
    return false;
  },
  '/visibility/credentials': ({ cloud: { remediation } }) => {
    return false;
  },
  '/visibility/identities/roles/:entity_uuid': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/identities/roles': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/identities/roles/details': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/identities/roles/policy': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/identities/roles/policy/details': ({
    cloud: { remediation },
  }) => {
    return remediation.view('Any');
  },

  '/visibility/organization': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },
  '/visibility/organization/:org_id': ({ cloud: { remediation } }) => {
    return false;
    // return remediation.view('Any');
  },
  '/visibility/organization/:org_id/insight/details': ({
    cloud: { remediation },
  }) => {
    return false;
    // return remediation.view('Any');
  },

  '/visibility/tags': up => {
    const {
      ariksa: { tenancy },
      cloud: { remediation },
    } = up;
    return false;
  },
  '/visibility/audit': ({ ariksa: { tenancy }, cloud: { remediation } }) => {
    return false;
  },
  '/visibility/assessment': ({
    ariksa: { tenancy },
    cloud: { remediation },
  }) => {
    return tenancy.view() || remediation.view('Any');
  },
  '/visibility/vulnerability': ({
    ariksa: { tenancy },
    cloud: { remediation },
  }) => {
    return tenancy.view() || remediation.view('Any');
  },
  '/visibility/network/reports': ({
    ariksa: { tenancy },
    cloud: { remediation },
  }) => {
    return tenancy.view() || remediation.view('Any');
  },
  '/visibility/data': ({ ariksa: { tenancy }, cloud: { remediation } }) => {
    return tenancy.view() || remediation.view('Any');
  },
  '/visibility/jit-requests': ({
    ariksa: { tenancy },
    cloud: { remediation },
  }) => {
    //return false;
    return tenancy.view() || remediation.view('Any');
  },

  // policy-hub
  '/policy-hub': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/blueprint': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/blueprint/add': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/blueprint/:id': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },

  '/policy-hub/policy': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/policy/add': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/policy/edit/:id': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },

  '/policy-hub/remediation': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/remediation/add': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/remediation/edit/:id': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },

  '/policy-hub/workflow': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/workflow/add': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },
  '/policy-hub/workflow/edit/:uuid': up => {
    const { cloudResources } = up;
    return (
      !!cloudResources.size ||
      up.cloud.policy.view('Any') ||
      up.cloud.reporting.view('Any')
    );
  },

  // compliance
  '/compliance': ({ ariksa: { policy, tenancy }, cloud: { remediation } }) => {
    return policy.view() || tenancy.view() || remediation.view('Any');
  },
  '/compliance/compare-policy': ({
    ariksa: { policy, tenancy },
    cloud: { remediation },
  }) => {
    return policy.view() || tenancy.view() || remediation.view('Any');
  },
  '/compliance/resources': ({
    ariksa: { policy, tenancy },
    cloud: { remediation },
  }) => {
    return policy.view() || tenancy.view() || remediation.view('Any');
  },

  '/compliance/rules': ({
    ariksa: { policy, tenancy },
    cloud: { remediation },
  }) => {
    return policy.view() || tenancy.view() || remediation.view('Any');
  },

  // reports
  '/reports': () => true,
  '/reports/all': () => true,
  '/reports/dashboard': () => true,
  '/reports/dashboard/edit': () => true,
  '/reports/edit': () => true,

  // setup
  '/setup/accounts/edit/:provider/:id': ({ ariksa }) => ariksa.tenancy.view(),
  '/setup/accounts/edit/:provider/:id/:accountType': ({ ariksa }) =>
    ariksa.tenancy.view(),
  '/setup/accounts/add/outpost/:id': ({ ariksa }) => ariksa.tenancy.view(),
  '/setup/accounts/add/data-outpost/:id': ({ ariksa }) => ariksa.tenancy.view(),
  '/setup': ({
    ariksa: { system, policy, tenancy },
    cloud: { compliance },
  }) => {
    return system.view() || tenancy.view() || compliance.view('Any');
  },

  '/setup/accounts/:uuid/member_accounts': ({ ariksa }) =>
    ariksa.tenancy.view(),
  '/setup/accounts': ({ ariksa }) => ariksa.tenancy.view(),
  '/setup/accounts/add/:provider': ({ ariksa }) => ariksa.tenancy.view(),
  '/setup/accounts/inventory/:uuid': ({ ariksa }) => {
    return ariksa.tenancy.view();
  },

  '/setup/outpost': ({ ariksa: { system, tenancy } }) => {
    return true;
  },
  '/setup/outpost/add/:type': ({ ariksa: { system, tenancy } }) => {
    return true;
  },

  '/setup/jit': ({ ariksa: { system, tenancy } }) => {
    return true;
  },
  '/setup/jit/add': ({ ariksa: { system, tenancy } }) => {
    return true;
  },
  '/setup/roles': ({ ariksa: { system, tenancy } }) => {
    return false;
    // return system.view() || tenancy.view();
  },
  '/setup/roles/add': ({ ariksa: { system, tenancy } }) => {
    return system.create() || tenancy.create();
  },
  '/setup/users': ({ ariksa: { system, tenancy } }) => {
    return system.view() || tenancy.view();
  },
  '/setup/users/add': ({ ariksa: { system, tenancy } }) => {
    return system.create() || tenancy.create();
  },
  '/setup/users/edit/:user_id': ({ ariksa: { system, tenancy } }) => {
    return system.update() || tenancy.update();
  },
  '/setup/groups': ({ ariksa: { system, tenancy } }) => {
    return system.view() || tenancy.view();
  },
  '/setup/groups/add': ({ ariksa: { system, tenancy } }) => {
    return system.create() || tenancy.create();
  },
  '/setup/groups/edit/:group_id': ({ ariksa: { system, tenancy } }) => {
    return system.update() || tenancy.update();
  },
  '/setup/groups/view/:group_id': ({ ariksa: { system, policy, tenancy } }) => {
    return system.view() || tenancy.view();
  },

  '/setup/ip-whitelisting': ({ ariksa: { system, tenancy } }) => {
    //WARNING(su): temporarily hidden
    return false;
    // return system.view() || tenancy.view();
  },

  '/setup/ip-whitelisting/add': ({ ariksa: { system, tenancy } }) => {
    return system.view() || tenancy.view();
  },

  '/setup/identity': ({ ariksa: { policy, tenancy } }) => {
    return policy.view() || tenancy.view();
  },

  '/setup/integrations': ({ ariksa: { policy, tenancy } }) => {
    return policy.view() || tenancy.view();
  },
  '/setup/integrations/jira/update/:jira_id': ({
    ariksa: { policy, tenancy },
  }) => {
    return policy.view() || tenancy.view();
  },
  '/setup/integrations/slack/update/:slack_id': ({
    ariksa: { policy, tenancy },
  }) => {
    return policy.view() || tenancy.view();
  },
  '/setup/integrations/teams/update/:teams_id': ({
    ariksa: { policy, tenancy },
  }) => {
    return policy.view() || tenancy.view();
  },
  '/setup/integrations/email/update/:email_id': ({
    ariksa: { policy, tenancy },
  }) => {
    return policy.view() || tenancy.view();
  },

  '/setup/environment': ({
    ariksa: { policy, tenancy },
    cloud: { compliance },
  }) => {
    return policy.view() || tenancy.view() || compliance.view('Any');
  },
  '/setup/environment/edit/:uuid': ({
    ariksa: { policy, tenancy },
    cloud: { compliance },
  }) => {
    return policy.view() || tenancy.view() || compliance.update('Any');
  },
  '/setup/environment/add': ({
    ariksa: { policy, tenancy },
    cloud: { compliance },
  }) => {
    return policy.view() || tenancy.view() || compliance.create('Any');
  },

  '/setup/data': ({ ariksa: { policy, tenancy }, cloud: { compliance } }) => {
    return policy.view() || tenancy.view() || compliance.create('Any');
  },

  '/organizations': ({ ariksa: { system } }) => {
    return system.view();
  },
  '/organizations/new-organization': ({ ariksa: { system } }) => {
    return system.view();
  },
  '/organizations/edit/:org_id': ({ ariksa: { system } }) => {
    return system.view();
  },

  // event-log
  '/event-log': ({ cloud: { remediation } }) => {
    return true; // sm: hiding for now
    // return remediation.view('Any');
  },

  // vulnerability
  '/vulnerability/:uuid': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },

  // pii
  '/pii/:uuid': ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },

  resources_user_map: ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },

  resources_resource_map: ({ cloud: { remediation } }) => {
    return remediation.view('Any');
  },

  select_cloud_account: ({ ariksa: { system } }) => {
    return !system.view();
  },

  // component permissions
};
