import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Flex, Stack, Text } from '@chakra-ui/react';
import { startCase } from 'lodash';
import { Application } from 'pixi.js';
import { useDispatch, useSelector } from 'react-redux';

import { GraphScale } from 'components/Visualization/PixiGraph/common/GraphScale';
import { GraphMenu } from 'components/Visualization/PixiGraph/components/GraphMenu';
import { GraphMenuItemTypes } from 'components/Visualization/PixiGraph/components/GraphMenuItem';
import { AGraphEdge } from 'components/Visualization/PixiGraph/core/Edge';
import { AGraph } from 'components/Visualization/PixiGraph/core/Graph';
import { AGraphNode } from 'components/Visualization/PixiGraph/core/Node';
import { AGraphRenderer } from 'components/Visualization/PixiGraph/core/Renderer';
import { connectedEntities } from 'components/Visualization/PixiGraph/core/utils';
import { AccessMapPaginationNode } from 'components/Visualization/PixiGraph/maps/AccessMap/elements/Node';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectAccess } from 'containers/Visibility/Access/selectors';

interface IGraphMenu {
  app: Application;
  graph: AGraph<AGraphNode, AGraphEdge>;
  renderer: AGraphRenderer;
}

export const PostureGraphMenu: FC<IGraphMenu> = props => {
  const { graph, app, renderer } = props;

  const [tooltipStyle, setTooltipStyle] = useState<any>({
    left: 0,
    top: 0,
    width: 20,
    height: 30,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState<any>('');

  useEffect(() => {
    const hideMenu = () => {
      setShowTooltip(false);
    };
    graph.on('wheel', hideMenu);
    return () => {
      graph.off('wheel', hideMenu);
    };
  }, [graph]);

  useEffect(() => {
    const onActive = entries => {
      const elements = entries.map(e => e.data);
      if (!elements.length) {
        setShowTooltip(false);
        return;
      }

      const element = elements[0];
      const entry = entries[0];
      const { id, data } = element;

      // console.log(data, entry);
      if (data.agnostic_name === 'Egress') {
        setTooltipStyle({
          left: entry.minX + app.stage.x + 'px',
          top: entry.minY + app.stage.y + 40 + 'px',
          width: 170 + 'px',
          // height: 30,
        });
        setShowTooltip(true);
        setTooltipInfo(
          <Stack p={2}>
            <Text>Egress traffic observed to one or more external IPs:</Text>
            <Text color={'medium'}>
              Malicious IP: {data.properties.is_malicious ? 'Yes' : 'No'}
            </Text>
          </Stack>,
        );
      }

      if (data.agnostic_name === 'Ingress') {
        setTooltipStyle({
          left: entry.minX + app.stage.x + 'px',
          top: entry.minY + app.stage.y + 40 + 'px',
          width: 170 + 'px',
          // height: 30,
        });
        setShowTooltip(true);
        setTooltipInfo(
          <Stack p={2}>
            <Text>Ingress traffic seen on ports:</Text>
            <Stack spacing={1}>
              {data.properties.ports?.map((p, index) => (
                <Text color={'medium'}>
                  {index + 1}: {p}
                </Text>
              ))}
            </Stack>
          </Stack>,
        );
      }
    };

    graph.on('mouseover:element', onActive);
    return () => {
      graph.off('mouseover:element', onActive);
    };
  }, [app.stage.x, app.stage.y, graph]);

  return (
    <>
      {showTooltip && (
        <Flex
          pos={'absolute'}
          bg={'#2D3748'}
          borderRadius={4}
          color={'white'}
          align={'center'}
          fontSize={'xs'}
          px={2}
          {...tooltipStyle}
        >
          {tooltipInfo}
        </Flex>
      )}
    </>
  );
};
