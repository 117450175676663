import React from 'react';

import { Box, Stack, Center } from '@chakra-ui/react';

import { DeleteActionButton } from 'app/components';
import { ActionButton, EditIcon } from 'app/components';

export const renderActions = ({ row, onEdit, onDelete, userCount = 1 }) => {
  const saasRootAdmin =
    row.organization === 'master' && row.username === 'admin';
  return (
    <Stack isInline spacing={0}>
      <Center w="full">
        <ActionButton
          label="Edit"
          icon={<EditIcon />}
          onClick={() => onEdit(row)}
          isDisabled={saasRootAdmin}
        />
        <DeleteActionButton
          onConfirm={onDelete.bind(this, row)}
          isDisabled={
            saasRootAdmin ||
            userCount === 1 ||
            row?.operations?.delete?.allowed === false
          }
          name={row?.username}
          type="user"
          label={<Box maxW={200}>{row?.operations?.delete?.reason}</Box>}
        />
      </Center>
    </Stack>
  );
};

export const isMasterOrg = ({ orgName }) => {
  return orgName === 'master';
};
