import React, { FC, useEffect, useMemo, useReducer } from 'react';

import { Box } from '@chakra-ui/react';
import { filter, find, includes, join, map, some, split } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useLocation } from 'react-use';
import { customTheme } from 'theme';

import { severityOptions } from 'components/DataDisplay/Utils/utils';
import { Select, SelectHistoricalOptions } from 'components/index';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { useSearchParamFunctions } from 'containers/App/hooks/useSearchParamFunctions';
import {
  selectActiveCloudAccount,
  selectActiveEnvironment,
} from 'containers/App/selectors';
import { actions } from 'containers/Findings/Alerts/slice';
import { FindingsIssuesSearchParamProps } from 'containers/Findings/Alerts/types';
import { alertStatusOptions } from 'containers/Findings/Alerts/utils';
import { useSharedApis } from 'containers/SharedState/hooks/useSharedApis';
import { selectSharedState } from 'containers/SharedState/selectors';
import { actions as sharedStateActions } from 'containers/SharedState/slice';
import { useSearchParams } from 'hooks/useSearchParams';
import { toTitleCase } from 'utils/string';

import { selectAlerts } from '../../selectors';

export const AlertFilters: FC = () => {
  const dispatch = useDispatch();
  const {
    alertCategories,
    aggregatedFindings,
    alerts,
    entityDetails,
    startDate,
    groupByResource,
  } = useSelector(selectAlerts);
  const { environmentId } = useSelector(selectActiveEnvironment);
  const { accountId } = useSelector(selectActiveCloudAccount);
  const {
    aiParamExtraction,
    regions,
    blueprints,
    alertResourceTypes,
    riskContext,
  } = useSelector(selectSharedState);
  const {
    getResourceAlias,
    getAlertResourceTypeOptionsWithAll,
  } = useResourceType();
  const { getBlueprints } = useSharedApis();
  const navigate = useNavigate();
  const params = useSearchParams<FindingsIssuesSearchParamProps>();
  const location = useLocation();
  const { updateValue } = useSearchParamFunctions();
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      blueprintId: {},
      resourceType: [],
      alertCategory: [],
      severity: [],
      alertCategoryOptions: [],
      resourceTypeOptions: [],
      regionOptions: [],
      blueprintOptions: [],
      region: {},
      alertStatus: alertStatusOptions[0],
    },
  );

  const isLoading = useMemo(() => {
    return (
      alerts.isLoading ||
      aggregatedFindings.isLoading ||
      aiParamExtraction.isLoading ||
      riskContext.isLoading ||
      some(entityDetails, o => o.isLoading)
    );
  }, [
    alerts.isLoading,
    aggregatedFindings.isLoading,
    aiParamExtraction.isLoading,
    riskContext.isLoading,
    entityDetails,
  ]);

  //set parameters from localstorage to url
  useDeepCompareEffect(() => {
    const alertStatus = localStorage.getItem('findings_issues_alert_status');
    const alertCategory = localStorage.getItem(
      'findings_issues_alert_categories',
    );
    const severity = localStorage.getItem('findings_issues_severity');
    const resourceType = localStorage.getItem('findings_issues_resource_type');
    const blueprintId = localStorage.getItem('findings_issues_blueprint_id');
    const region = localStorage.getItem('findings_issues_region');
    //const categoryClass = localStorage.getItem('findings_issues_category_class');
    let searchParams = '';
    const setValue = (f, v) => {
      searchParams = searchParams + (!!searchParams ? '&' : '') + f + '=' + v;
    };

    if (!!alertStatus) setValue('alertStatus', alertStatus);
    if (!!alertCategory) setValue('alertCategory', alertCategory);
    if (!!severity) setValue('severity', severity);
    if (!!resourceType) setValue('resourceType', resourceType);
    if (!!blueprintId) setValue('blueprintId', blueprintId);
    if (!!region) setValue('region', region);
    //if (!!categoryClass) setValue('categoryClass', categoryClass);
    if (!!searchParams) navigate(location.pathname + '?' + searchParams);
  }, [history, location.pathname]);

  useDeepCompareEffect(() => {
    const data = aiParamExtraction.data;
    const alertCategory = !!data?.alert_category
      ? [data?.alert_category]
      : !!data?.alertCategories
      ? data?.alertCategories
      : undefined;
    const severity = !!data?.severity
      ? [data?.severity]
      : !!data?.severities
      ? split(data?.severities, ',')
      : undefined;
    const resourceType = !!data?.resource_type
      ? [data?.resource_type]
      : !!data?.resourceTypes
      ? split(data?.resourceTypes, ',')
      : undefined;
    const categoryClass = data?.category_class || undefined;
    let searchParams = '';
    const setValue = (f, v) => {
      searchParams = searchParams + (!!searchParams ? '&' : '') + f + '=' + v;
    };

    if (!!alertCategory) setValue('alertCategory', alertCategory);
    if (!!severity) setValue('severity', severity);
    if (!!resourceType) setValue('resourceType', resourceType);
    if (!!categoryClass) setValue('categoryClass', categoryClass);
    if (!!searchParams) navigate(location.pathname + '?' + searchParams);
    dispatch(
      actions.updateAllFilters({
        searchString: data?.search || undefined,
        startTime: data?.start_date || undefined,
        endTime: data?.end_date || undefined,
        category_class: data?.category_class || undefined,
      }),
    );
  }, [aiParamExtraction.data, dispatch]);

  useEffect(() => {
    getBlueprints();
  }, [getBlueprints]);

  useEffect(() => {
    const alertStatus = params?.alertStatus;
    updateState({
      alertStatus: !!alertStatus
        ? find(alertStatusOptions, o => o.value === alertStatus)
        : alertStatusOptions[0],
    });
  }, [params.alertStatus]);

  //set alert category options
  useEffect(() => {
    const options = [
      //{ label: 'All alert categories', value: undefined },
      ...map(alertCategories.data, o => ({
        label: toTitleCase(o),
        value: o,
      })),
    ];
    updateState({
      alertCategoryOptions: options,
    });
  }, [alertCategories.data]);

  //set alert category
  useEffect(() => {
    const categories = params.alertCategory;
    const alertCategories = !!categories
      ? map(split(categories, ','), o => ({
          label: toTitleCase(o),
          value: o,
        }))
      : [];
    updateState({
      alertCategory: alertCategories,
    });
  }, [dispatch, params.alertCategory, state.alertCategoryOptions]);

  //set severity
  useEffect(() => {
    const severities = params?.severity;
    updateState({
      severity: !!severities
        ? filter(severityOptions, o =>
            includes(split(severities, ','), o.value?.toUpperCase()),
          )
        : [],
    });
  }, [dispatch, params?.severity, state.severityOptions]);

  //set resource type options
  useEffect(() => {
    const options = getAlertResourceTypeOptionsWithAll(alertResourceTypes.data);
    updateState({
      resourceTypeOptions: options,
    });
  }, [alertResourceTypes.data, getAlertResourceTypeOptionsWithAll]);

  //set resource type
  useEffect(() => {
    const types = params?.resourceType;
    updateState({
      resourceType: !!types
        ? filter(state.resourceTypeOptions, o =>
            includes(split(types, ','), o.value),
          )
        : [],
    });
  }, [
    dispatch,
    params.resourceType,
    getResourceAlias,
    state.resourceTypeOptions,
  ]);

  //set blueprint options
  useEffect(() => {
    const options = [
      { label: 'All blueprints', value: undefined },
      ...(blueprints.data?.map(item => {
        return {
          label: item.name,
          value: item?.id,
        };
      }) ?? []),
    ];

    updateState({
      blueprintOptions: options,
    });
  }, [blueprints.data]);

  //set blueprint_id
  useEffect(() => {
    const blueprintId = params?.blueprintId;
    updateState({
      blueprintId: !!blueprintId
        ? find(state.blueprintOptions, o => o.value === blueprintId)
        : state.blueprintOptions[0],
    });
  }, [dispatch, params.blueprintId, state.blueprintOptions]);

  //get regions
  useEffect(() => {
    dispatch(
      sharedStateActions.getRegions({
        q: { environmentId, accountId: !!accountId ? [accountId] : [] },
      }),
    );
  }, [dispatch, environmentId, accountId]);

  //set region options
  useEffect(() => {
    const options = [
      { label: 'All', value: '' },
      ...map(regions.data, o => ({
        label: o,
        value: o,
      })),
    ];
    updateState({
      regionOptions: options,
    });
  }, [regions.data]);

  //set region
  useEffect(() => {
    const region = !!params?.region
      ? { label: params?.region, value: params?.region }
      : state.regionOptions[0];
    updateState({
      region,
    });
  }, [dispatch, params.region, state.regionOptions]);

  const maxWidthStyles = {
    menu: provided => ({
      ...provided,
      width: 'max-content',
      minWidth: '100%',
    }),
    placeholder: st => ({
      ...st,
      color: customTheme.colors.gray['400'],
    }),
  };

  return (
    <>
      {!groupByResource && (
        <Box w="140px" zIndex={900}>
          <Select
            options={alertStatusOptions}
            value={state.alertStatus}
            onChange={s => {
              updateValue(
                'alertStatus',
                s?.value,
                'findings_issues_alert_status',
              );
            }}
            showIconInValueContainer
            isDisabled={isLoading}
          />
        </Box>
      )}
      <Box w={48} zIndex={900}>
        <Select
          options={severityOptions}
          value={state.severity}
          onChange={s => {
            updateValue(
              'severity',
              join(
                map(s, o => o?.value?.toUpperCase()),
                ',',
              ),
              'findings_issues_severity',
            );
          }}
          showIconInValueContainer
          isMulti
          showTotalSelected
          styles={maxWidthStyles}
          placeholder="All severities"
          selectedMessage={
            state.severity?.length === 1 ? 'severity' : 'severities'
          }
          isDisabled={isLoading}
        />
      </Box>
      <Box w="220px" zIndex={900}>
        <Select
          options={state.alertCategoryOptions}
          value={state.alertCategory}
          onChange={s =>
            updateValue(
              'alertCategory',
              join(
                map(s, o => o?.value),
                ',',
              ),
              'findings_issues_alert_categories',
            )
          }
          showIconInValueContainer
          isMulti
          showTotalSelected
          styles={maxWidthStyles}
          placeholder="All alert categories"
          selectedMessage={
            'alert ' +
            (state.alertCategory?.length === 1 ? 'category' : 'categories')
          }
          isDisabled={isLoading}
        />
      </Box>
      <Box w="220px" zIndex={900}>
        <Select
          options={state.resourceTypeOptions}
          value={state.resourceType}
          onChange={s =>
            updateValue(
              'resourceType',
              join(
                map(s, o => o.value),
                ',',
              ),
              'findings_issues_resource_type',
            )
          }
          isLoading={alertResourceTypes.isLoading}
          showIconInValueContainer
          menuShouldScrollIntoView
          isMulti
          showTotalSelected
          styles={maxWidthStyles}
          placeholder="All resource types"
          selectedMessage={
            'resource type' + (state.resourceType?.length === 1 ? '' : 's')
          }
          isDisabled={isLoading}
        />
      </Box>
      {!groupByResource && (
        <Box w="160px" zIndex={900}>
          <Select
            options={state.blueprintOptions}
            value={state.blueprintId}
            onChange={s => {
              updateValue(
                'blueprintId',
                s?.value,
                'findings_issues_blueprint_id',
              );
            }}
            isLoading={blueprints.isLoading}
            showIconInValueContainer
            styles={maxWidthStyles}
            isDisabled={isLoading}
          />
        </Box>
      )}
      <SelectHistoricalOptions
        historicalTime={startDate}
        setHistoricalTime={s => {
          dispatch(actions.updateStartDate(s));
        }}
        isDisabled={isLoading}
        width={48}
      />
    </>
  );
};
