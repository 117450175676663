import React, { FC } from 'react';

import { AlertSeverityInitializer } from '@ariksa/notification/api';
import { Box, Center, HStack, Stack } from '@chakra-ui/react';
import { map } from 'lodash';
import { customTheme } from 'theme';

import { Card } from 'components/DataDisplay';
import { CDashboard } from 'components/Visualization';
import { useCategoryOverview } from 'containers/Findings/FindingsOverview/Overview/useCategoryOverview';

interface Props {
  alertCategory: string;
  severity: AlertSeverityInitializer;
  showAsCards?: boolean;
}

export const IndividualCategoryOverview: FC<Props> = props => {
  const { alertCategory, severity, showAsCards = false } = props;

  const { dashboardWidgets, availableWidgets } = useCategoryOverview({
    alertCategory,
    severity,
  });

  return showAsCards ? (
    <Box>
      <CDashboard dashboardWidgets={dashboardWidgets} />
    </Box>
  ) : (
    <Card>
      <HStack w="full" spacing={6}>
        {map(availableWidgets, (o, key) => (
          <>
            <Stack w={o.w} spacing={4}>
              <Box w="full" fontWeight={600} fontSize="md">
                {o.name}
              </Box>
              <Box
                w="full"
                h="233px"
                px={1}
                py={key === 'AlertSummary' ? 2 : 1}
              >
                {o.components?.content}
              </Box>
            </Stack>
            {key !== 'top_aggregated_alerts' && (
              <Box pt={10} pb={16} h="full">
                <Center
                  h="full"
                  borderRight="1px solid"
                  borderColor={customTheme.colors.gray['100']}
                  my={6}
                />
              </Box>
            )}
          </>
        ))}
      </HStack>
    </Card>
  );
};
