import React, { useState, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { VMImageIcon } from 'components/Icons';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

export const MostDeployedVulnerableImages = () => {
  const { vulnerableImages } = useSelector(selectVulnerabilities);
  const [data, setData] = useState<any>([]);

  /*useEffect(() => {
    const items = map(vulnerabilitiesBySources.data?.items, o => ({
      name: o.agnostic_name,
      Critical: o.vulnerability_count.critical,
      High: o.vulnerability_count.high,
      Medium: o.vulnerability_count.medium,
      Other:
        (o.vulnerability_count.low ?? 0) + (o.vulnerability_count.unknown ?? 0),
    }));

    setData(items);
  }, [vulnerabilitiesBySources.data]);*/

  return (
    <DashboardOverviewCard
      label="Most deployed vulnerable images"
      icon={<VMImageIcon />}
      iconBgColor="critical"
      isLoading={vulnerableImages.isLoading}
      //rightHeader={renderCloudIcons(vulnerableImages.data?.clouds)}
      content={<Box w="full" h="full"></Box>}
    />
  );
};
