import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GroupId } from 'api/auth/types';
import { selectUser } from 'containers/App/selectors';
import { groupsSaga } from 'containers/Setup/Groups/saga';
import { useInjector } from 'utils/inject';

import { selectGroups } from '../selectors';
import { actions, reducer, sliceKey } from '../slice';

import { GroupDetails } from './GroupDetails';

export interface IEditGroup {}

export const ViewGroup = (props: IEditGroup) => {
  useInjector(sliceKey, reducer, groupsSaga);

  const dispatch = useDispatch();
  const { info } = useSelector(selectUser);
  const { groupUpdate, loadGroupById } = useSelector(selectGroups);
  const params = useParams<GroupId>();

  useEffect(() => {
    if (params.group_id) {
      dispatch(
        actions.getGroupById({
          id: params.group_id,
          organization: info.org_id,
        }),
      );
    }
  }, [dispatch, info.org_id, params]);

  const handleSubmit = data => {
    const { roles = [], admin, name } = data;
    let admins: any = [];
    if (Array.isArray(admin)) {
      admins = admin.map(r => r.value);
    } else {
      admins = [{ ...admin.value }];
    }
    dispatch(
      actions.updateGroup({
        organization: info.org_id,
        user_group: {
          ...groupUpdate.payload,
          admins,
          roles: roles.map(r => r.value),
          name,
        },
      }),
    );
  };

  const { name } = groupUpdate.payload;

  const formData = {
    name,
    admins: groupUpdate?.payload?.admins,
    roles: groupUpdate?.payload?.roles,
    account_ids: groupUpdate?.payload.account_ids,
    user_ids: groupUpdate?.payload.users?.map(u => u.id),
    auto_add_accounts: groupUpdate?.payload.auto_add_accounts,
  };

  return (
    <GroupDetails
      isDataLoading={loadGroupById.loading}
      isLoading={groupUpdate.loading}
      formData={formData}
      onSubmit={handleSubmit}
      action={'view'}
    />
  );
};
