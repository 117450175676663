import { RulesApiDeleteMetadataRequest } from '@ariksa/compliance-policies';
import {
  RuleRead,
  RulesApiCreatePolicyRequest,
  RulesApiDeletePolicyRequest,
  RulesApiGetAllRuleCategoryRequest,
  RulesApiGetPolicyRequest,
  RulesApiUpdatePolicyRequest,
  RulesApiGetAllRulesRequest,
  BlueprintApiAddRulesRequest,
  RulesApiCreateMetadataRequest,
  PolicyMetaData,
  RulesApiGetMetadataRequest,
  PageRuleReadWithMetadata,
  RulesApiGetAttachedBlueprintsRequest,
} from '@ariksa/compliance-policies/api';
import {
  CloudProvider,
  SearchApiSaveSearchTermRequest,
  SearchApiSearchSuggestionsRequest,
  SearchApiSearchTermsRequest,
  SearchSuggestResponse,
  SearchWrapper,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummaryResponse,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertWorkflowApiEditWorkflowRequest,
} from '@ariksa/notification/api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  get,
  isArray,
  isEmpty,
  isNumber,
  isObject,
  isString,
  set,
} from 'lodash';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';
import { Optional } from 'types/utils';

import { PageInfo } from 'components/Navigation';
import { queryId } from 'containers/PolicyHub/utils';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  AriksaQueryBuildId,
  AriksaSearchQuery,
  AriksaSearchQueryChange,
  ContainerState,
  QueryOptionsStatus,
  SearchApiSearchSuggestionsRequestParams,
} from './types';

// The initial state of the PolicyHub container
export const initialState: ContainerState = {
  selectedPolicy: {},
  rules: {
    results: [],
    loading: false,
    error: null,
  },
  policyAction: QueryState.init({}),

  searchQuery: {
    build: {
      id: queryId(),
      resource_options: [],
      resource_options_status: QueryOptionsStatus.unknown,
    },
  },
  searchQuerySuggestion: QueryState.init<SearchSuggestResponse>({
    suggestions: {},
    is_complete: false,
  }),
  suggestFor: { for_field: 'resource_attributes', build_id: '' },
  policies: PagedQueryState.init([]),
  alertSummary: QueryState.init<AggregatedAlertsSummaryResponse>({
    summary: {},
  }),
  createPolicy: QueryState.init({} as RuleRead),
  policyById: QueryState.init({} as RuleRead),
  saveQuery: QueryState.init({} as SearchWrapper),
  deletePolicy: QueryState.init({}),
  updatePolicy: QueryState.init({}),
  queryById: QueryState.init({}),

  ruleCategories: QueryState.init([]),
  activeAlertCategory: '',

  attachWorkflow: QueryState.init({}),
  attachedBlueprints: QueryState.init([]),
  alertCategories: QueryState.init([]),

  policyContext: QueryState.init({}),
  policyContexts: QueryState.init([]),
  searchQueryCloudProvider: undefined,
};

const policyHubSlice = createSlice({
  name: 'policyHub',
  initialState,
  reducers: {
    /*create policy context*/
    createPolicyContext(
      state,
      action: QueryAction<any, RulesApiCreateMetadataRequest>,
    ) {
      state.policyContext = QueryState.next(state.policyContext, action);
    },

    /*delete policy context*/
    deletePolicyContext(
      state,
      action: QueryAction<any, RulesApiDeleteMetadataRequest>,
    ) {
      state.policyContext = QueryState.next(state.policyContext, action);
    },

    /*delete policy context*/
    getPolicyContexts(
      state,
      action: QueryAction<PolicyMetaData[], RulesApiGetMetadataRequest>,
    ) {
      state.policyContexts = QueryState.next(state.policyContexts, action);
    },

    /*get Alert Categories*/
    getAlertCategories(
      state,
      action: QueryAction<string[], RulesApiGetAllRuleCategoryRequest>,
    ) {
      state.alertCategories = QueryState.next(state.alertCategories, action);
    },

    /*get attached blueprints*/
    getAttachedBlueprints(
      state,
      action: QueryAction<string[], RulesApiGetAttachedBlueprintsRequest>,
    ) {
      state.attachedBlueprints = QueryState.next(
        state.attachedBlueprints,
        action,
      );
    },

    updateActiveAlertCategory(state, action: PayloadAction<string>) {
      state.activeAlertCategory = action.payload;
    },
    getRuleCategories(
      state,
      action: QueryAction<string[], RulesApiGetAllRuleCategoryRequest>,
    ) {
      state.ruleCategories = QueryState.next(state.ruleCategories, action);
    },
    resetSearchQuery(state) {
      state.searchQuery = initialState.searchQuery;
    },
    setSearchQuery(state, action: PayloadAction<AriksaSearchQuery>) {
      state.searchQuery = action.payload;
    },
    updateSearchQuery(state, action: PayloadAction<AriksaSearchQueryChange>) {
      updateSearchQueryObject(state.searchQuery, action.payload);
    },

    getSearchQuerySuggestion(
      state,
      action: QueryAction<
        SearchSuggestResponse,
        SearchApiSearchSuggestionsRequestParams
      >,
    ) {
      state.searchQuerySuggestion = QueryState.next(
        state.searchQuerySuggestion,
        action,
      );
      const { suggestFor } = action.payload.q;
      const { for_field = '' } = suggestFor;

      state.suggestFor = suggestFor;

      const target = findTargetByBuildId(
        suggestFor.build_id,
        state.searchQuery,
      );
      const field = (for_field.toString().split('_')?.[1] ?? '').slice(0, -1);
      if (target && for_field.toString().includes('_')) {
        let status = QueryOptionsStatus.loading;
        set(target, `build.${field}_options_status`, status);
      }
    },
    checkQueryValidity(
      state,
      action: QueryAction<
        SearchSuggestResponse,
        SearchApiSearchSuggestionsRequest
      >,
    ) {
      state.searchQuery.is_complete = !!action.payload.data?.is_complete;
    },

    getPolicyById(
      state,
      action: QueryAction<RuleRead, RulesApiGetPolicyRequest>,
    ) {
      state.policyById = QueryState.next(state.policyById, action);
    },
    createPolicy(
      state,
      action: QueryAction<RuleRead, RulesApiCreatePolicyRequest>,
    ) {
      state.createPolicy = QueryState.next(state.createPolicy, action);
    },
    saveQuery(
      state,
      action: QueryAction<SearchWrapper, SearchApiSaveSearchTermRequest>,
    ) {
      state.saveQuery = QueryState.next(state.saveQuery, action);
    },
    getQueryById(
      state,
      action: QueryAction<SearchWrapper[], SearchApiSearchTermsRequest>,
    ) {
      state.queryById = QueryState.next(state.queryById, action, {
        mapData: r => r?.[0] ?? {},
      });
    },

    getPolicies(
      state,
      action: QueryAction<PageRuleReadWithMetadata, RulesApiGetAllRulesRequest>,
    ) {
      state.policies = PagedQueryState.next(state.policies, action, {
        mapTotalCount: r => r.total ?? 0,
        mapData: r => r.items,
      });
    },
    updatePoliciesPagination(state, action: PayloadAction<PageInfo>) {
      state.policies.page.info = action.payload;
    },
    deletePolicy(state, action: QueryAction<any, RulesApiDeletePolicyRequest>) {
      state.policyAction = QueryState.next(state.policyAction, action);
    },
    updatePolicy(state, action: QueryAction<any, RulesApiUpdatePolicyRequest>) {
      state.updatePolicy = QueryState.next(state.updatePolicy, action);
    },

    addPolicyToBlueprint(
      state,
      action: QueryAction<any, BlueprintApiAddRulesRequest>,
    ) {
      state.policyAction = QueryState.next(state.policyAction, action);
    },

    getAggregatedAlertsSummary(
      state,
      action: QueryAction<
        AggregatedAlertsSummaryResponse,
        AlertsApiGetAggregatedAlertsSummaryRequest
      >,
    ) {
      state.alertSummary = QueryState.next(state.alertSummary, action);
    },

    setCurrentPolicy(state, action: PayloadAction<any>) {
      state.selectedPolicy = action.payload;
    },

    attachWorkflow(
      state,
      action: QueryAction<any, AlertWorkflowApiEditWorkflowRequest>,
    ) {
      state.attachWorkflow = QueryState.next(state.attachWorkflow, action);
    },

    updateSearchQueryProvider(
      state,
      action: PayloadAction<Optional<CloudProvider>>,
    ) {
      state.searchQueryCloudProvider = action.payload;
    },
  },
});

export const updateSearchQueryObject = (
  query: AriksaSearchQuery,
  update: AriksaSearchQueryChange,
) => {
  // console.log(query, update);
  const { build_id, op, field, value, field_build_id } = update ?? {};
  const target = findTargetByBuildId(build_id, query);
  // console.log(action.payload, target);
  if (target) {
    // console.log(op, field, build_id);
    if (op === 'set') {
      console.log(target, update);
      if (field.toString().includes('_options')) {
        set(target, `build.${field}`, value);
        console.log(
          'setting field',
          `build.${field}_status`,
          QueryOptionsStatus.loaded,
        );
        set(target, `build.${field}_status`, QueryOptionsStatus.loaded);
      } else {
        set(target, field, value);
        // if non option fields is set mark the query incomplete
        query.is_complete = false;
      }

      if (field === 'relationship') {
        set(
          target,
          'build.resource_options_status',
          QueryOptionsStatus.unknown,
        );
      }
      if (field === 'operator' && value === undefined) {
        set(
          target,
          'build.operator_options_status',
          QueryOptionsStatus.unknown,
        );
      }
      if (field === 'values' && value === undefined) {
        set(target, 'build.value_options_status', QueryOptionsStatus.unknown);
      }
    }

    if (op === 'remove') {
      if (target) {
        if (field === 'clauses') {
          set(target, 'clauses', null);
        }
        if (field === 'attributes') {
          set(target, 'attributes', null);
        }
        if (field === 'that') {
          target.that = target.that.filter(
            t => get(t, 'build.id') !== field_build_id,
          );
        }
        if (field === 'attribute') {
          target.attributes = target.attributes.filter(
            t => get(t, 'build.id') !== field_build_id,
          );
        }
      }
    }
  }

  return query;
};

// recursively findPath if any field has targeted buildId
const findTargetByBuildId = (buildId: string, query: AriksaSearchQuery) => {
  // console.log('Search for', buildId, query);
  return findObjectByBuildId(buildId, query);
};

export const findObjectByBuildId = (
  buildId: AriksaQueryBuildId,
  obj: any,
  path: string[] = [],
): any => {
  let found = null;
  if (isString(obj) || isNumber(obj) || isEmpty(obj)) return found;

  // findPath in array
  if (isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      path.push(String(i));
      const el = obj[i];
      found = findObjectByBuildId(buildId, el, path);
      if (found) return found;
      path.pop();
    }
  }

  // check current object has target build_id
  if (isObject(obj)) {
    // console.log(obj, get(obj, 'build.id'));
    if (get(obj, 'build.id') === buildId) return obj;

    // findPath in object fields
    for (const key in obj) {
      if (key === 'build') continue;
      const el = obj[key];
      path.push(key);
      found = findObjectByBuildId(buildId, el, path);
      if (found) return found;
      path.pop();
    }
  }

  return found;
};

export const { actions, reducer, name: sliceKey } = policyHubSlice;
