import React, { useEffect, useState } from 'react';

import { identity } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Card } from 'app/components/DataDisplay/Card';
import { Form } from 'app/components/DataEntry/Form';
import { PageHeaderWithIcon } from 'components/DataDisplay';
import { GroupIcon } from 'components/Icons';
import { Layout } from 'components/Layout';
import { selectOnboardedCloudAccountsWithAccess } from 'containers/App/selectors';
import { selectGroups } from 'containers/Setup/Groups/selectors';
import { rolesSaga } from 'containers/Setup/Roles/saga';
import { selectRoles } from 'containers/Setup/Roles/selectors';
import * as rolesSlice from 'containers/Setup/Roles/slice';
import { setupUsersSaga } from 'containers/Setup/Users/saga';
import { selectSetupUsers } from 'containers/Setup/Users/selectors';
import * as usersSlice from 'containers/Setup/Users/slice';
import { form_styles } from 'containers/Setup/Users/styles';
import { useInjector } from 'utils/inject';
import { toPascalCase } from 'utils/string';

import { getOptions } from '../../utils';
import { groupsSaga } from '../saga';
import { actions, reducer, sliceKey } from '../slice';

export interface IGroupDetails {
  action: 'create' | 'update' | 'view';
  isLoading?: boolean;
  formData: any;
  isDataLoading: boolean;

  onSubmit(data: any): void;
}

export const GroupDetails = (props: IGroupDetails) => {
  useInjector(sliceKey, reducer, groupsSaga);
  useInjector(usersSlice.sliceKey, usersSlice.reducer, setupUsersSaga);
  useInjector(rolesSlice.sliceKey, rolesSlice.reducer, rolesSaga);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { action, isLoading, isDataLoading, formData, onSubmit } = props;

  const { accounts } = useSelector(selectGroups);
  const cloudAccounts = useSelector(selectOnboardedCloudAccountsWithAccess);
  const { users } = useSelector(selectSetupUsers);
  const { roles: rolesData } = useSelector(selectRoles);

  const [actionType, setActionType] = useState(false);
  const [name, setName] = useState(formData.name);
  const [disabledEditRole, setDisableEditRole] = useState(false);
  const [autoAddAccounts, setAutoAddAccounts] = useState(
    formData.auto_add_accounts,
  );
  // const [formRoles, setFormRoles] = useState(rolesData.roles.map);

  const [formAccounts, setFormAccounts] = useState(
    getOptions(
      accounts.data.filter(a => formData.account_ids?.includes(a.uuid)),
      'name',
    ),
  );
  const [formAdmin, setFormAdmin] = useState<any>(null);
  const [formUsers, setFormUsers] = useState(
    formData.users?.map(u => ({
      label: `${u.first_name} ${u.last_name}`,
      value: u,
    })),
  );

  const [formRoles, setFormRoles] = useState(
    getOptions(formData.roles, 'name'),
  );

  useEffect(() => {
    setFormRoles(getOptions(formData.roles, 'name'));
  }, [formData]);

  useEffect(() => {
    const admin = ['update', 'view'].includes(action)
      ? getOptions(formData.admins, 'username')?.[0]
      : {
          // Selecting the first user from the users list.
          value: users?.payload[0],
          label: users?.payload[0]?.username,
        };
    setFormAdmin(admin);
  }, [action, formData.admins, users?.payload]);

  useEffect(() => {
    const usersData = formData.user_ids
      ?.map(id => users.payload?.find(u => u.id === id))
      .filter(identity);

    setFormUsers(
      usersData?.map(u => ({
        label: `${u.first_name} ${u.last_name}`,
        value: u,
      })),
    );
  }, [formData.users, formData.user_ids, users.payload]);

  useEffect(() => {
    setFormAccounts(
      getOptions(
        accounts.data.filter(a => formData.account_ids?.includes(a.uuid)),
        'name',
      ),
    );
  }, [accounts.data, formData.account_ids]);

  useEffect(() => {
    setAutoAddAccounts(formData.auto_add_accounts);
  }, [formData.auto_add_accounts]);

  useEffect(() => {
    dispatch(
      actions.getCloudAccounts({
        q: {},
        page: { page_number: 1, page_size: 100 },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (action === 'view') {
      setActionType(true);
    }
    // Disable role edit if only one role is assigned
    // if (formData.roles?.length === 1 && action === 'update') {
    //   setDisableEditRole(true);
    // }
  }, [action, formData.roles]);

  const handleBack = () => {
    navigate('/setup/groups');
  };

  // get org users
  useEffect(() => {
    if (['update', 'create'].includes(action)) {
      dispatch(usersSlice.actions.loadUsers());
    }
  }, [action, dispatch]);

  useEffect(() => {
    dispatch(rolesSlice.actions.getRoles());
  }, [dispatch]);

  const handleSubmit = data => {
    onSubmit({
      ...data,
      accounts: formAccounts,
      users: formUsers,
      auto_add_user: autoAddAccounts,
      roles: formRoles,
      admin: formAdmin,
    });
  };

  const schemaForm = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-zA-Z0-9-_ ]*$/i,
        'Only alphanumeric, hyphen, underscore and space is allowed!',
      ),
  });

  return (
    <Layout>
      <Card styles={{ card: { pl: 24, overflow: 'visible' } }}>
        <Form
          isLoading={isDataLoading}
          title={
            <PageHeaderWithIcon
              label={`${toPascalCase(action)} Groups`}
              icon={<GroupIcon />}
              reversed
            />
          }
          formValidation={schemaForm}
          schema={{
            name: {
              type: 'text',
              label: 'Group Name',
              isRequired: true,
              placeholder: 'Engineering-US',
              isDisabled: actionType,
              value: name,
              onChange: setName,
              tooltip:
                'Only alphanumeric, hyphen, underscore and space is allowed.',
            },
            admins: {
              type: 'react-select',
              label: 'Group Admin',
              isRequired: true,
              isDisabled: actionType,
              value: formAdmin,
              options: users.payload.map(u => ({
                value: u,
                label: u.username,
              })),
              onChange: setFormAdmin,
            },
            ...(action === 'update' || action === 'view'
              ? {
                  roles: {
                    type: 'react-select',
                    label: 'Role',
                    isDisabled: actionType || disabledEditRole,
                    isMulti: true,
                    value: formRoles,
                    options: rolesData.roles.map(u => ({
                      value: u,
                      label: u.name,
                      key: u.name,
                    })),
                    onChange: setFormRoles,
                  },
                  users: {
                    type: 'react-select',
                    label: 'User',
                    isMulti: true,
                    isDisabled: actionType,
                    value: formUsers,
                    options: users.payload.map(u => ({
                      value: u,
                      label: u.first_name,
                      key: u.first_name,
                    })),
                    onChange: setFormUsers,
                  },
                  accounts: {
                    type: 'react-select',
                    label: 'Accounts assigned',
                    isMulti: true,
                    isDisabled: actionType,
                    value: formAccounts,
                    options: cloudAccounts.data.map(u => ({
                      value: u,
                      label: u.name,
                    })),
                    onChange: opts => {
                      setFormAccounts(opts);
                    },
                  },
                }
              : {}),
            auto_add_accounts: {
              type: 'switch',
              label: 'Automatically add newly onboarded accounts to group',
              isChecked: autoAddAccounts,
              onChange: setAutoAddAccounts,
            },
          }}
          styles={form_styles}
          buttonOptions={{
            submit: {
              name: 'Okay',
              isLoading,
            },
            reset: {
              isVisible: true,
              name: 'Cancel',
              onClick: () => navigate('/setup/groups'),
            },
          }}
          handleSubmit={action === 'view' ? handleBack : handleSubmit}
        />
      </Card>
    </Layout>
  );
};
