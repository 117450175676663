import React, { FC, useCallback, useEffect, useState } from 'react';

import { RuleCreate, RuleRead } from '@ariksa/compliance-policies/api';
import { Resource } from '@ariksa/inventory-core/api';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dict, Optional } from 'types/utils';

import { CustomPolicyDetails } from 'containers/PolicyHub/Policies/CreatePolicy/CustomPolicyDetails';
import { cleanUpObject, parseQuery } from 'containers/PolicyHub/Policies/utils';
import { policyHubSaga } from 'containers/PolicyHub/saga';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import * as policyHubSlice from 'containers/PolicyHub/slice';
import { useInjector } from 'utils/inject';

interface IUpdatePolicy {}

export const UpdatePolicy: FC<IUpdatePolicy> = props => {
  useInjector(policyHubSlice.sliceKey, policyHubSlice.reducer, policyHubSaga);

  const { searchQuery, policyById, updatePolicy } = useSelector(
    selectPolicyHub,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{ policy_id: string }>();
  const [formData, setFormData] = useState<Dict<any>>({
    alert_category: '',
    category_class: '',
    severity: '',
  });

  // get custom query
  const getCustomQueryById = useCallback(
    queryId => {
      dispatch(
        actions.getQueryById({
          q: { id: queryId },
          onSuccess: res => {
            const query = parseQuery(
              res?.[0].wrapper ?? {},
              res?.[0].attributes ?? [],
            );
            dispatch(actions.setSearchQuery(query));
          },
        }),
      );
    },
    [dispatch],
  );

  // get policy
  const getPolicyById = useCallback(
    policyId => {
      dispatch(
        actions.getPolicyById({
          q: { policyId },
          onSuccess: (res: Optional<RuleRead>) => {
            setFormData({
              alert_category: res?.alert_category,
              category_class: res?.category_class,
              severity: res?.severity,
            });
            getCustomQueryById(res?.search_id!);
          },
        }),
      );
    },
    [dispatch, getCustomQueryById],
  );

  useEffect(() => {
    getPolicyById(params.policy_id);
  }, [getPolicyById, params.policy_id]);

  const handleSubmit = data => {
    const { summary, description, category, severity, alertDescription } = data;
    const { alertCategory = {}, categoryClass = {} } = category ?? {};
    console.log('Save query');
    // first save the query

    const policy: RuleCreate = {
      config_id: summary,
      is_enabled: true,
      search_id: '',
      compliance_description: description,
      alert_description: alertDescription,
      alert_category: alertCategory.value,
      category_class: categoryClass.value,
      severity: severity.value,
    };
    dispatch(
      actions.saveQuery({
        q: {
          searchWrapper: {
            id: policyById.data.search_id,
            wrapper: {
              resource: cleanUpObject(
                cloneDeep(searchQuery.resource),
              ) as Resource,
            },
            name: summary ?? '-',
          },
        },
        onSuccess: res => {
          if (!res?.id) {
            console.error('failed to save custom search query');
          }

          policy.search_id = res?.id;
          dispatch(
            actions.updatePolicy({
              q: { policyId: params.policy_id!, ruleCreate: policy },
              onSuccess: () => {
                navigate('/policy-hub/policy');
              },
            }),
          );
        },
      }),
    );
  };

  const updateForm = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <CustomPolicyDetails
      onSubmit={handleSubmit}
      action={'Update'}
      alert_description={policyById.data.alert_description}
      compliance_description={policyById.data.compliance_description}
      alert_category={formData.alert_category}
      category_class={formData.category_class}
      severity={formData.severity}
      config_id={policyById.data.config_id}
      updateForm={updateForm}
      isLoading={updatePolicy.isLoading}
    />
  );
};
