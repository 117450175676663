import React, { FC, useCallback, useState } from 'react';

import { RuleRead } from '@ariksa/compliance-policies';
import { Box, useDisclosure, Stack, Center } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';

import {
  renderSeverityBar,
  StackedCell,
  WithResourceIcon,
} from 'components/DataDisplay';
import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { TableColumnProps } from 'components/DataDisplay/NewTable/types';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import {
  AddIcon,
  CheckmarkIcon,
  EditIcon,
  TagIcon,
  TrashIcon,
} from 'components/Icons';
import { DeleteConfirmationModal, TableActionsMenu } from 'components/Overlay';
import { useResourceType } from 'containers/App/hooks/useResourceType';
import { EditPolicyContext } from 'containers/PolicyHub/Components/EditPolicyContext';
import { AddPolicyToBlueprint } from 'containers/PolicyHub/Policies/AddPolicyToBlueprint';
import { selectPolicyHub } from 'containers/PolicyHub/selectors';
import { actions } from 'containers/PolicyHub/slice';
import {
  renderPolicyContext,
  renderPolicyCreated,
} from 'containers/PolicyHub/utils';
import { toTitleCase } from 'utils/string';

export const PoliciesTable: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { policies, activeAlertCategory, policyAction } = useSelector(
    selectPolicyHub,
  );
  const deletePolicy = useDisclosure();
  const addPolicy = useDisclosure();
  const editContext = useDisclosure();
  const [activePolicy, setActivePolicy] = useState<RuleRead>({} as RuleRead);
  const { getResourceAlias, getCloudAgnosticName } = useResourceType();

  const menuItems = row => [
    {
      label: 'Edit',
      icon: <EditIcon />,
      onClick: () => {
        navigate(`/policy-hub/policy/edit/${row.id}`);
      },
      isDisabled: !row?.created_by || row?.created_by === 'system',
    },
    {
      label: 'Add to Blueprint',
      icon: <AddIcon />,
      onClick: () => {
        setActivePolicy(row);
        addPolicy.onOpen();
      },
    },
    {
      label: 'Edit context',
      onClick: () => {
        setActivePolicy(row);
        editContext.onOpen();
      },
      icon: <TagIcon w={5} h={5} />,
      //isDisabled: !row?.created_by || row?.created_by === 'system',
    },
    /*{
      label: 'Disable',
      onClick: () => {},
      iconType: IconTypes.Disable,
      isDisabled: !row?.created_by || row?.created_by === 'system',
    },*/
    {
      label: 'Delete',
      icon: <TrashIcon color="red" />,
      onClick: () => {
        setActivePolicy(row);
        deletePolicy.onOpen();
      },
      isDisabled: !row?.created_by || row?.created_by === 'system',
    },
  ];

  const renderActions = ({ row }) => (
    <Box onClick={e => e.stopPropagation()}>
      <TableActionsMenu menuItems={menuItems(row)} />
    </Box>
  );

  const columns: TableColumnProps[] = [
    {
      header: <Box pl="thLeftPaddingWithIcon">Description</Box>,
      accessor: 'config_id',
      render: ({ row }) => (
        <WithResourceIcon
          resourceType={row?.resource_type}
          tooltip={
            <Stack>
              <Box color="orange">{row?.config_id}</Box>
              <Box>{row?.compliance_description || row?.alert_description}</Box>
            </Stack>
          }
        >
          {row?.compliance_description || row?.alert_description}
        </WithResourceIcon>
      ),
    },
    {
      header: 'Alert Category',
      accessor: 'alert_category',
      render: ({ value }) => toTitleCase(value),
      align: 'left',
      styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    {
      header: 'Resource Type',
      accessor: 'resource_type',
      render: ({ value }) => (
        <StackedCell
          upper={getResourceAlias(value)}
          lower={getCloudAgnosticName(value)}
        />
      ),
      align: 'left',
      styles: { width: '250px', cell: { maxWidth: '250px' } },
    },
    {
      header: 'Severity',
      accessor: 'severity',
      render: ({ value }) => renderSeverityBar({ value, isInline: false }),
      align: 'left',
      styles: { width: '100px', cell: { maxWidth: '80px' } },
    },
    {
      header: 'Context',
      accessor: 'policy_metadata',
      render: renderPolicyContext,
      styles: { width: '80px', cell: { maxWidth: '80px' } },
    },
    {
      header: 'Status',
      accessor: 'is_enabled',
      render: ({ value }) => (
        <Center>
          <CustomTooltip label={value ? 'Enabled' : 'Disabled'}>
            <Box
              boxSize={4}
              p={0.5}
              as={value ? CheckmarkIcon : CheckmarkIcon}
              bg={
                value
                  ? customTheme.colors.green['300']
                  : customTheme.colors.gray['200']
              }
              color="white"
              borderRadius="full"
            ></Box>
          </CustomTooltip>
        </Center>
      ),
      //align: 'left',
      styles: { width: '100px', cell: { maxWidth: '100px' } },
    },
    {
      header: 'Created',
      accessor: 'created_by',
      render: renderPolicyCreated,
      align: 'left',
      styles: { width: '200px', cell: { maxWidth: '200px' } },
    },
    {
      header: 'Actions',
      render: renderActions,
      styles: { width: '80px', cell: { maxWidth: '80px' } },
    },
  ];

  const getPolicies = useCallback(() => {
    dispatch(
      actions.getPolicies({
        q: {
          alertCategory: activeAlertCategory,
          page: policies.page.info.page_number,
          size: policies.page.info.page_size,
        },
      }),
    );
  }, [dispatch, activeAlertCategory, policies.page.info]);

  const handleDeletePolicy = () => {
    dispatch(
      actions.deletePolicy({
        q: { policyId: activePolicy?.id! },
        onSuccess: () => {
          deletePolicy.onClose();
          getPolicies();
        },
      }),
    );
  };

  return (
    <Box h={'full'} w={'full'}>
      <Table
        columns={columns}
        data={policies.data}
        isLoading={policies.isLoading}
        pagination={{
          pageInfo: policies.page.info,
          totalCount: policies.page.totalCount,
          onChange: pageInfo =>
            dispatch(actions.updatePoliciesPagination(pageInfo)),
        }}
        styles={{ header: { zIndex: 800 } }}
      />
      {addPolicy.isOpen && (
        <AddPolicyToBlueprint
          isOpen={addPolicy.isOpen}
          onClose={addPolicy.onClose}
          row={activePolicy}
        />
      )}
      {editContext.isOpen && (
        <EditPolicyContext
          isOpen={editContext.isOpen}
          onClose={editContext.onClose}
          row={activePolicy}
          onSuccess={() => getPolicies()}
        />
      )}
      {activePolicy && (
        <DeleteConfirmationModal
          label={'Delete'}
          isOpen={deletePolicy.isOpen}
          onClose={deletePolicy.onClose}
          header="Detach Environment"
          name={activePolicy.config_id}
          onConfirm={handleDeletePolicy}
          type={'Policy'}
          isLoading={policyAction.isLoading}
        />
      )}
    </Box>
  );
};
