import { createContext, useContext } from 'react';

export interface VulnerabilityContextProps {
  getVulnerabilities();
  isPatchable: Record<string, any>;
  packageType: Record<string, any>;
  packageName: Record<string, any>;
  severity: Record<string, any>;
  isExpanded?: boolean;
  isExploitable: Record<string, any>;
  updateValue(field, value);
}

export const VulnerabilityContext = createContext<VulnerabilityContextProps>({
  getVulnerabilities: () => {},
  packageType: {},
  isPatchable: {},
  packageName: {},
  severity: {},
  isExpanded: false,
  isExploitable: {},
  updateValue: () => {},
});

export const VulnerabilityContextProvider = VulnerabilityContext.Provider;

export const useVulnerabilityContext = () => useContext(VulnerabilityContext);
