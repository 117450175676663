import React from 'react';

import { Box, Link, Stack, Image, Center, HStack } from '@chakra-ui/react';
import { customTheme } from 'theme';

import { HelpCount } from 'components/DataDisplay/Utils/HelpCount';
import { HelpHeader } from 'components/DataDisplay/Utils/HelpHeader';

import snowflake_1 from './snowflake_1.png';
import snowflake_2 from './snowflake_2.png';

interface Props {
  onClose: () => void;
}

export const SnowflakeHelp = (props: Props) => {
  const { onClose } = props;

  const renderImage = (src, alt) => (
    <Center>
      <Box
        border="1px solid"
        borderRadius={0}
        borderColor={customTheme.colors.gray['200']}
      >
        <Image src={src} w="full" fit="cover" alt={alt} />
      </Box>
    </Center>
  );

  return (
    <Stack h="full">
      <HelpHeader>Get Locator URL</HelpHeader>
      <Stack px={4} spacing={6}>
        <HStack align={'start'}>
          <HelpCount count={1} />
          <Box>
            Log in to{' '}
            <Link color="primary" href="https://app.snowflake.com" isExternal>
              Snowflake
            </Link>
            .
          </Box>
        </HStack>
        <HStack align={'start'}>
          <HelpCount count={2} />
          <Stack spacing={4}>
            <Box>Go to accounts and copy the locator</Box>
            {renderImage(snowflake_1, 'snowflake_1')}
            {renderImage(snowflake_2, 'snowflake_2')}
          </Stack>
        </HStack>
      </Stack>
    </Stack>
  );
};
