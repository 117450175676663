import React, { useCallback, useEffect, useState } from 'react';

import { AlertResponse } from '@ariksa/notification';
import {
  Box,
  Divider,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useInventoryInsightService } from 'services/Inventory/useInventoryInsightService';
import { useInventoryService } from 'services/Inventory/useInventoryService';
import { customTheme } from 'theme';
import { Dict } from 'types/utils';

import {
  CustomTooltip,
  DetailsIdentifier,
  H3,
  H4,
  renderRiskContext,
} from 'components/DataDisplay';
import {
  CancelButton,
  PrimaryButton,
  textFieldStyles,
} from 'components/DataEntry';
import { LearnMoreIcon, StopSignIcon } from 'components/Icons';
import { AutomatedRemediationOfIssuesHelp } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Aggregated/Remediation/AutomatedRemediationOfIssuesHelp';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';
import { actions } from 'containers/Findings/FindingsOverview/slice';
import { CloudAccountName } from 'containers/Setup/CloudAccounts/utils';

interface ExecuteCommandProps {
  alert: AlertResponse;
  remediation: any;
}

export const ExecuteCommand = (props: ExecuteCommandProps) => {
  const { alert, remediation } = props;
  const dispatch = useDispatch();
  const { executeRemediation } = useSelector(selectFindingsOverview);
  const helpDisclosure = useDisclosure();

  const [variables, setVariables] = useState<
    Dict<{ key: string; value: string }>
  >({});
  const [command, setCommand] = useState(
    remediation?.cli_commands?.command ?? '',
  );
  const [confirmed, setConfirmed] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [autoRemediate, setAutoRemediate] = useState(false);

  const { inventoryInsightV2 } = useInventoryInsightService();
  const { riskContext } = useInventoryService();

  //set auto remediate switch value
  useEffect(() => {
    setAutoRemediate(remediation?.is_execution_enable);
  }, [remediation]);

  //get Resource Details
  useEffect(() => {
    inventoryInsightV2.sync({
      insightV2Request: {
        resource_uuids: [alert?.entity_uuid!],
        account_id: [alert?.account_id!],
      },
    });
  }, [alert, inventoryInsightV2]);

  //get Risk Context
  useEffect(() => {
    riskContext.sync({
      riskContextRequest: {
        uuids: [alert?.entity_uuid!],
      },
    });
  }, [alert, riskContext]);

  const updateCommand = useCallback(() => {
    let newCommand = remediation?.cli_commands?.command ?? '';
    Object.keys(variables).forEach(variable => {
      const { key, value } = variables[variable];
      newCommand = updateCommandWithVariables(newCommand, variable, key, value);
    });
    setCommand(newCommand);
  }, [remediation, variables]);

  const updateCommandWithVariables = (
    command: string,
    field: string,
    key: string,
    value: string,
  ) => {
    // console.log('Update command', field, key, value, command);
    const index = command.indexOf(field);
    const before = command.slice(0, index);
    const after = command.slice(index);
    const keyIndex = after.indexOf(key);
    const beforeKey = after.slice(0, keyIndex);
    const afterKey = after.slice(keyIndex).slice(key.length);
    return `${before}${beforeKey}${value}${afterKey}`;
  };

  useEffect(() => {
    updateCommand();
  }, [variables, remediation, updateCommand]);

  const handleExecute = useCallback(() => {
    dispatch(
      actions.executeRemediation({
        q: {
          resourceType: alert.resource as any,
          resourceUuid: alert.entity_uuid!,
          account: alert.account_id!,
          checkName: alert.alert_rule_id!,
          command: command,
          autoRemediation: true,
          policyId: alert?.policy_id,
          automaticExecutionEnabled: autoRemediate,
        },
      }),
    );
  }, [alert, command, dispatch, autoRemediate]);

  const handleCancel = () => {
    dispatch(actions.setPrepareRemediation(null));
  };

  useEffect(() => {
    const variableKeysArray = Object.keys(variables);
    if (
      variableKeysArray?.length ===
        remediation?.remediate_input_prams?.length &&
      !some(variables, o => !o?.value)
    )
      setIsValid(true);
    else setIsValid(false);
  }, [variables, remediation?.remediate_input_prams]);

  const renderAutoRemediationSwitch = () => (
    <Switch
      isDisabled={!remediation?.execution}
      isChecked={autoRemediate}
      onChange={e => setAutoRemediate(e.target.checked)}
    />
  );

  return (
    <Stack spacing={4}>
      <Stack>
        <H3>Issue description</H3>
        <Text>{alert.message ?? '-'}</Text>
      </Stack>
      <Divider borderColor="primary" />
      <Stack w={'full'} spacing={3}>
        <H3>Resource details</H3>
        <HStack w={'full'} align={'start'}>
          <Stack flex={1} spacing={1}>
            <DetailsIdentifier
              label="Resource type"
              value={alert.entity_type ?? alert.resource}
              pb={1}
            />
            <DetailsIdentifier
              label={'Cloud ID'}
              value={alert.entity_id}
              pb={1}
            />
            <DetailsIdentifier
              label={'Risk Context'}
              value={renderRiskContext(
                riskContext.data?.[alert?.entity_uuid!] ?? [],
              )}
              isLoading={riskContext.isLoading}
            />
            <DetailsIdentifier
              label={'Owned by'}
              value={inventoryInsightV2?.data?.items?.[0]?.owner}
              isLoading={inventoryInsightV2.isLoading}
            />
          </Stack>
          <Stack flex={1} align={'start'}>
            <DetailsIdentifier
              label={'Account'}
              value={<CloudAccountName accountId={alert.account_id} />}
            />
            <DetailsIdentifier label={'Region'} value={alert.region} />
            <DetailsIdentifier
              label={'VPC'}
              value={
                inventoryInsightV2?.data?.items?.[0]?.vpc ??
                inventoryInsightV2?.data?.items?.[0]?.vpc_id
              }
              isLoading={inventoryInsightV2.isLoading}
            />
            <DetailsIdentifier
              label={'Compliance Standards'}
              value={
                <HStack>
                  {alert.blueprints?.map((blueprint: any) => (
                    <Text>{blueprint.blueprint_name}</Text>
                  ))}
                </HStack>
              }
            />
          </Stack>
        </HStack>
      </Stack>
      <Divider borderColor="primary" />
      <H3>Remediation command</H3>
      <Box
        border={'1px solid #aaa'}
        borderRadius={6}
        p={4}
        color={isValid ? customTheme.colors.green['300'] : 'red'}
      >
        {command ?? '-'}
      </Box>
      {!!remediation?.remediate_input_prams?.length && (
        <Box color="red">
          The command requires {remediation?.remediate_input_prams?.length}{' '}
          additional input
          {remediation?.remediate_input_prams?.length === 1 ? '' : 's'} from
          user before it can be executed. Please provide inputs below:
        </Box>
      )}
      <Stack>
        {remediation?.remediate_input_prams?.map((param: any) => {
          return (
            <HStack>
              <H4 minW={250}>{param.variable}</H4>
              <Input
                size={'sm'}
                {...textFieldStyles.input}
                onChange={e => {
                  setVariables({
                    ...variables,
                    [param.variable]: { key: param.key, value: e.target.value },
                  });
                }}
              />
            </HStack>
          );
        })}
      </Stack>

      <Stack spacing={6}>
        <Stack>
          <HStack color={'red'}>
            <Box boxSize={8}>
              <StopSignIcon />
            </Box>
            <H4>PLEASE CONFIRM</H4>
          </HStack>
          <Text>
            Ariksa will make changes to the resource shown using the command
            shown above. This change cannot be undone. Please ensure that the
            parameters to this command are accurate and that this change is
            warranted and scoped to avoid any disruptions to your business.
            Ariksa cannot verify the input values for accuracy.
          </Text>
        </Stack>
        <Stack spacing={3}>
          <H4>
            Type “CONFIRM” in the box below for final confirmation to execute
          </H4>
          <Input
            placeholder={'Type CONFIRM to execute the command'}
            {...textFieldStyles.input}
            size={'sm'}
            onChange={e => {
              setConfirmed(e.target.value === 'CONFIRM');
            }}
          />
        </Stack>
        <HStack spacing={6}>
          <HStack>
            <H4>Automatically remediate this issue:</H4>
            {remediation?.execution ? (
              renderAutoRemediationSwitch()
            ) : (
              <CustomTooltip
                label={remediation?.disable_remediation_msg}
                w={48}
              >
                {renderAutoRemediationSwitch()}
              </CustomTooltip>
            )}
          </HStack>
          <PrimaryButton
            leftIcon={<LearnMoreIcon w={5} h={5} color="primary" />}
            color={customTheme.colors.gray['300']}
            _hover={{}}
            _active={{}}
            _focus={{}}
            onClick={() => helpDisclosure.onOpen()}
          >
            Learn more
          </PrimaryButton>
        </HStack>
        <HStack justify={'space-between'} pt={2}>
          <CancelButton onClick={handleCancel} />
          <PrimaryButton
            bg={'primary'}
            color={'white'}
            isDisabled={!confirmed || !isValid}
            onClick={handleExecute}
            isLoading={executeRemediation.isLoading}
          >
            Execute
          </PrimaryButton>
        </HStack>
      </Stack>
      {helpDisclosure.isOpen && (
        <AutomatedRemediationOfIssuesHelp {...helpDisclosure} />
      )}
    </Stack>
  );
};
