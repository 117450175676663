import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { errorToast } from 'components/Toast';
import { selectUser } from 'containers/App/selectors';
import { useInjector } from 'utils/inject';

import { groupsSaga } from '../saga';
import { selectGroups } from '../selectors';
import { actions, reducer, sliceKey } from '../slice';

import { GroupDetails } from './GroupDetails';

export const CreateGroup = () => {
  useInjector(sliceKey, reducer, groupsSaga);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { groupCreate } = useSelector(selectGroups);

  const handleSubmit = data => {
    const { name, admin, auto_add_accounts } = data;

    if (name.length === 0 || admin.length === 0) {
      errorToast({
        title: 'Failed to create Group',
        description: 'Name and Group admin should not be empty!',
      });
      navigate('/setup/groups');
    } else {
      dispatch(
        actions.createGroup({
          q: {
            admin_id: admin.value.id,
            name,
            organization: user.info.org_id,
            auto_add_accounts,
          },
          onSuccess: () => {
            navigate('/setup/groups');
          },
          errMsg: 'Failed to create group',
        }),
      );
    }
  };

  return (
    <GroupDetails
      isDataLoading={false}
      isLoading={groupCreate.isLoading}
      formData={groupCreate}
      onSubmit={handleSubmit}
      action={'create'}
    />
  );
};
