import React, { FC, useEffect } from 'react';

import { Box, Stack } from '@chakra-ui/react';
import { findIndex } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TabItemProps, Tabs } from 'components/Navigation';
import { MetadataDrawerSummary } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataDrawerSummary';
import { useMetadataDrawerTabs } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/MetadataDrawers/ResourceMetadata/hooks/useMetadataDrawerTabs';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';
import { useActiveResourceActions } from 'containers/ActiveCloudResource/context/useActiveResourceActions';
import { selectActiveResource } from 'containers/ActiveCloudResource/selectors';
import { actions } from 'containers/ActiveCloudResource/slice';

export const ResourceMetadata: FC = () => {
  const { tabIndex } = useActiveResourceContext();
  const { resourceType, activeTabIndex, activeTabName } = useSelector(
    selectActiveResource,
  );
  const dispatch = useDispatch();
  const {
    getResourceDetails,
    getResourceInsight,
    getVulnerabilitySummary,
    getEntitySeverity,
    getAccessMapData,
    getGcpResourceDetails,
    getResourceRiskContext,
  } = useActiveResourceActions();

  // load resource data only when the drawer is opened
  useEffect(() => {
    if (resourceType?.toLowerCase()?.startsWith('gcp')) {
      // getResourceInsight();
      getGcpResourceDetails();
    } else {
      getResourceDetails();
      getResourceInsight();
    }
  }, [
    getResourceDetails,
    getResourceInsight,
    getGcpResourceDetails,
    resourceType,
  ]);

  useEffect(() => {
    getResourceRiskContext();
    getEntitySeverity();
  }, [getResourceRiskContext, getEntitySeverity]);

  useEffect(() => {
    getVulnerabilitySummary();
  }, [getVulnerabilitySummary]);

  useEffect(() => {
    getAccessMapData();
  }, [getAccessMapData]);

  const { getMetadataDrawerTabs } = useMetadataDrawerTabs();

  const tabs: TabItemProps[] = getMetadataDrawerTabs(resourceType);

  useEffect(() => {
    // if tab name is present set the index accordingly
    if (!activeTabName) {
      dispatch(
        actions.setActiveTabName(
          tabs[activeTabIndex]?.title.toLowerCase() ?? '',
        ),
      );
    } else {
      const index = findIndex(
        tabs,
        t => t?.title.toLowerCase() === activeTabName,
      );
      if (index === -1) {
        dispatch(actions.setActiveTabIndex(0));
        dispatch(actions.setActiveTabName(tabs[0].title.toLowerCase()));
      } else {
        dispatch(actions.setActiveTabIndex(index));
      }
    }
  }, [activeTabIndex, activeTabName, dispatch, tabs]);

  return (
    <Stack w="full" h="full" spacing={5}>
      <MetadataDrawerSummary />
      <Box h="full">
        <Tabs
          items={tabs}
          currentIndex={tabIndex}
          onChange={index => {
            dispatch(actions.setActiveTabIndex(index));
            dispatch(actions.setActiveTabName(tabs[index].title.toLowerCase()));
          }}
        />
      </Box>
    </Stack>
  );
};
