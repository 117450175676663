import React, { FC } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  HStack,
  Stack,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/all';
import { FiChevronDown } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { customTheme } from 'theme';

import { AriksaIcon, Tag } from 'components/DataDisplay';
import { PrimaryButton } from 'components/DataEntry';
import { AriksaLogoOnlyIcon } from 'components/Icons/ReactCustomIcons/AriksaLogoOnlyIcon';
import { Tabs } from 'components/Navigation';
import { RemediationCommand } from 'containers/Findings/FindingsOverview/Components/AlertTables/Components/AlertDrawer/Components/Individual/Remediation/RemediationCommand';
import { selectFindingsOverview } from 'containers/Findings/FindingsOverview/selectors';

interface IRemediationAccordion {
  remediations?: any[];
  allowToggle?: boolean;

  onExecuteRemediation?(remediation: any): void;

  showRemediateButton?: boolean;
  remediateDisclosure: UseDisclosureProps;
}

export const RemediationAccordion: FC<IRemediationAccordion> = props => {
  const {
    remediations = [],
    allowToggle = true,
    onExecuteRemediation,
    remediateDisclosure,
  } = props;
  const { executeRemediation } = useSelector(selectFindingsOverview);
  const handleExecuteRemediation = (e, remediation) => {
    e.stopPropagation();
    onExecuteRemediation?.(remediation);
    remediateDisclosure?.onOpen?.();
  };

  return (
    <Accordion allowToggle={allowToggle}>
      <Stack spacing={4}>
        {remediations?.map((r, index) => (
          <AccordionItem border={'none'} key={index + 'remediation-key'}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  px={2}
                  border={'1px solid'}
                  borderColor={customTheme.colors.gray['100']}
                  borderRadius={5}
                  outline={'none'}
                >
                  <HStack justify={'space-between'} w={'full'}>
                    <Center>
                      <AriksaIcon
                        icon={
                          r?.created_by === 'Ariksa' ? (
                            <AriksaLogoOnlyIcon />
                          ) : (
                            <Box fontWeight={600} fontSize="lg">
                              C
                            </Box>
                          )
                        }
                        iconFilled={!(r?.created_by === 'Ariksa')}
                        size={'sm'}
                      />
                    </Center>
                    <HStack justify={'space-between'} w={'full'}>
                      <HStack flex="1" textAlign={'left'} overflow={'hidden'}>
                        <Text fontSize={'md'}>{r.name}</Text>
                        {r?.created_by !== 'Ariksa' && (
                          <Tag
                            label="Custom"
                            styles={{
                              label: { color: 'white' },
                              tag: { bg: 'primary' },
                            }}
                          />
                        )}
                      </HStack>

                      <PrimaryButton
                        size={'xs'}
                        isDisabled={!r.execution_button_flag}
                        onClick={e => handleExecuteRemediation(e, r)}
                        isLoading={executeRemediation.isLoading}
                      >
                        Remediate
                      </PrimaryButton>
                    </HStack>
                    {allowToggle && (
                      <Center>
                        {isExpanded ? <FiChevronDown /> : <FiChevronRight />}
                      </Center>
                    )}
                  </HStack>
                </AccordionButton>
                {allowToggle && (
                  <AccordionPanel
                    mt={4}
                    pb={4}
                    px={0}
                    bg={'gray.50'}
                    border={'1px'}
                    borderColor={'gray.100'}
                    borderRadius={5}
                  >
                    <Tabs
                      items={[
                        ...(r.cli_commands
                          ? [
                              {
                                title: 'AWS CLI',
                                key: 'cli',
                                component: (
                                  <RemediationCommand
                                    type={'Steps for AWS CLI'}
                                    steps={
                                      r.cli_commands?.cli_setup_steps ?? []
                                    }
                                    aws_console_steps={
                                      r.console_commands?.aws_console_steps ??
                                      []
                                    }
                                    command={r.cli_commands?.command ?? '-'}
                                    document={r.cli_commands?.document ?? ''}
                                    note={r.cli_commands?.note ?? ''}
                                  />
                                ),
                              },
                            ]
                          : []),
                        ...(r.console_commands
                          ? [
                              {
                                title: 'AWS Console',
                                key: 'console',
                                component: (
                                  <RemediationCommand
                                    type={'Steps for AWS console'}
                                    steps={r.console_commands.steps ?? []}
                                    console_path={
                                      r.console_commands?.console_path ?? ''
                                    }
                                    aws_console_steps={
                                      r.console_commands?.aws_console_steps ??
                                      []
                                    }
                                    note={r.console_commands?.note ?? ''}
                                  />
                                ),
                              },
                            ]
                          : []),
                      ]}
                    />
                    {/*{r.execution && (*/}
                    {/*  <Flex pl={14}>*/}
                    {/*    <CustomTooltip*/}
                    {/*      label={*/}
                    {/*        r.execution*/}
                    {/*          ? 'Execute remediation commands'*/}
                    {/*          : 'Ariksa lacks permissions for remediation'*/}
                    {/*      }*/}
                    {/*      placement={'top-start'}*/}
                    {/*    >*/}
                    {/*      <PrimaryButton*/}
                    {/*        isDisabled={!r.execution}*/}
                    {/*        onClick={(e) => handleExecuteRemediation(e, r)}*/}
                    {/*        isLoading={executeRemediation.isLoading}*/}
                    {/*      >*/}
                    {/*        Remediate*/}
                    {/*      </PrimaryButton>*/}
                    {/*    </CustomTooltip>*/}
                    {/*  </Flex>*/}
                    {/*)}*/}
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        ))}
      </Stack>
    </Accordion>
  );
};
