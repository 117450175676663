import React from 'react';

import { SensitivityCategory } from '@ariksa/data-scanning';
import { Box, Center, Stack } from '@chakra-ui/react';

import { getIcon, PageHeaderWithIcon } from 'components/DataDisplay';
import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import {
  AwsIcon,
  BitbucketIcon,
  GithubIcon,
  GitLabIcon,
  IconTypes,
  SnowflakeIcon,
} from 'components/Icons';

export const dataTableHeading = {
  DataSources: 'Data Sources',
  DataTypes: 'Data Types In Sources',
  DocumentTypes: 'Document Types',
  all: 'All Data Sources',
};

export const dataSideNavItemList = [
  {
    title: 'Scanned Sources',
    key: IconTypes.DataSources,
    iconType: 'Database',
  },
  {
    title: 'Document Types',
    key: 'DocumentTypes',
    iconType: IconTypes.Policy,
  },
  {
    title: 'Data Types',
    key: 'DataTypes',
    iconType: 'DataTypes',
  },
];

export const sensitiveCategories = [
  SensitivityCategory.Internal,
  SensitivityCategory.Restricted,
  SensitivityCategory.Confidential,
  SensitivityCategory.Public,
];

export const cloudOptions = [
  { label: 'AWS', value: 'AWS', icon: <AwsIcon /> },
  {
    label: 'Snowflake',
    value: 'Snowflake',
    icon: <SnowflakeIcon />,
  },
  { label: 'GitLab', value: 'GitLab', icon: <GitLabIcon /> },
  { label: 'GitHub', value: 'GitHub', icon: <GithubIcon /> },
  {
    label: 'Atlassian BitBucket',
    value: 'BitBucket',
    icon: <BitbucketIcon />,
  },
];

export const renderDataSecurityHeader = selectedTab => (
  <Box>
    <PageHeaderWithIcon
      label={dataTableHeading[selectedTab]}
      icon={getIcon(
        selectedTab === IconTypes.DataSources
          ? IconTypes.Database
          : selectedTab === 'DocumentTypes'
          ? IconTypes.Policy
          : selectedTab === 'all'
          ? IconTypes.AllFindings
          : IconTypes.DataTypes,
      )}
      reversed
      iconBgColor="primary"
    />
  </Box>
);

export const renderDataSecuritySummaryCount = (
  icon,
  count,
  label,
  isLoading = false,
  onClick = () => {},
  iconPadding = 0.5,
) => (
  <Stack
    w="full"
    spacing={4}
    onClick={onClick}
    cursor={!!onClick ? 'pointer' : 'default'}
  >
    <Center w="full" color="primary">
      <Center boxSize={8} p={iconPadding}>
        {icon}
      </Center>
    </Center>
    <Stack w="full" spacing={0}>
      <Center w="full" fontWeight={600}>
        {isLoading ? <CustomSpinner /> : count}
      </Center>
      <Center w="full" color="gray.300">
        {label}
      </Center>
    </Stack>
  </Stack>
);
