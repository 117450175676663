import React from 'react';

import { Box, Center, HStack, Table, Tr, Td, Tbody } from '@chakra-ui/react';
import { isEmpty, map } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customTheme } from 'theme';
import { colorOpacity } from 'theme/utils';

import { NoDataAvailable } from 'components/DataDisplay';
import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { CodeIcon, IconTypes } from 'components/Icons';
import { DashboardOverviewCard } from 'containers/Dashboard/utils/DashboardOverviewCard';
import { tdStyles } from 'containers/Dashboard/utils/styles';
import { renderEmptyRows } from 'containers/Dashboard/utils/utils';
import { selectVulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/selectors';
import { limitedString } from 'utils/string';

export const MostWidelyDeployedLibraries: React.FC = () => {
  const { mostWidelyDeployedLibraries } = useSelector(
    selectVulnerabilityOverview,
  );
  const navigate = useNavigate();

  return (
    <DashboardOverviewCard
      label="Most widely deployed libraries with vulnerabilities"
      icon={<CodeIcon />}
      isLoading={mostWidelyDeployedLibraries.isLoading}
      content={
        isEmpty(mostWidelyDeployedLibraries.data) ? (
          <NoDataAvailable />
        ) : (
          <Table w="full" h="full" size="sm">
            <Tbody>
              {map(mostWidelyDeployedLibraries.data, (o, index) => (
                <Tr key={'key-most-widely-deployed-libraries-' + index}>
                  <Td {...tdStyles}>
                    <HStack w="full">
                      <Box boxSize={6} color="primary">
                        <Center>
                          <CodeIcon />
                        </Center>
                      </Box>
                      <HStack
                        border="1px solid"
                        borderColor={customTheme.colors.gray['100']}
                        bg={colorOpacity(customTheme.colors.primary, 0.2)}
                        borderRadius={3}
                        px={1.5}
                        flex={1}
                        h={6}
                      >
                        <Box h={4} w="3px" bg={o?.severity?.toLowerCase()} />
                        <Box
                          fontWeight={600}
                          cursor="pointer"
                          onClick={() =>
                            navigate(
                              '/visibility/vulnerability?tab=' +
                                IconTypes.Packages +
                                '&search_term=' +
                                o.package_name,
                            )
                          }
                          flex={1}
                        >
                          <CustomTooltip label={o.package_name}>
                            {limitedString(o.package_name, 20)}
                          </CustomTooltip>
                        </Box>
                      </HStack>
                    </HStack>
                  </Td>
                  <Td {...tdStyles}>
                    <HStack spacing={1}>
                      <Box fontWeight={600}>{formatNumber(o?.occurrences)}</Box>
                      <Box>occurrences</Box>
                    </HStack>
                  </Td>
                  <Td {...tdStyles} pl={2}>
                    <HStack spacing={1}>
                      <CustomTooltip
                        label={
                          o.patch_available
                            ? 'Patch available'
                            : 'Patch not available'
                        }
                        width={20}
                        textAlign="center"
                      >
                        <Box
                          boxSize={2.5}
                          borderRadius={2}
                          bg={
                            o.patch_available
                              ? customTheme.colors.green['300']
                              : 'critical'
                          }
                        />
                      </CustomTooltip>
                      {o.patch_available ? (
                        <CustomTooltip label={o.patch_version}>
                          <Box fontWeight={600}>
                            Patch: {limitedString(o.patch_version, 22)}
                          </Box>
                        </CustomTooltip>
                      ) : (
                        <Box>Patch not available</Box>
                      )}
                    </HStack>
                  </Td>
                </Tr>
              ))}
              {renderEmptyRows(mostWidelyDeployedLibraries.data?.length)}
            </Tbody>
          </Table>
        )
      }
      iconBgColor="critical"
    />
  );
};
