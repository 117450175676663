/**
 *
 * useIntegrations hook
 *
 */

import React, { useCallback, useMemo, useState } from 'react';

import { AccountType } from '@ariksa/cloud-account';
import {
  CloudAccountApiOnboardCloudAccountRequest,
  CloudAccountApiUpdateCloudAccountRequest,
  CloudProviders,
} from '@ariksa/cloud-account/api';
import { Clients } from '@ariksa/notification';
import { ClientResponse } from '@ariksa/notification/api';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { EditIcon, IconTypes, TrashIcon } from 'app/components/Icons';
import { SideNavMenuItemProps } from 'app/components/Navigation/SideNav';
import { PageHeaderWithIcon } from 'components/DataDisplay';
import { ActionButton, AddButton, FormAction } from 'components/DataEntry';
import { Menu } from 'components/Overlay';
import { actions as appActions } from 'containers/App/slice';
import { SSO } from 'containers/Setup/Integrations/Components/SSO';
import { DataSources } from 'containers/Setup/Integrations/DataSources';
import { IdentityAndAccess } from 'containers/Setup/Integrations/IdentityAndAccess';
import { SIEM } from 'containers/Setup/Integrations/SIEM';
import { selectSetup } from 'containers/Setup/selectors';
import { actions as setupActions } from 'containers/Setup/slice';
import { actions as sharedStateActions } from 'containers/SharedState/slice';

import { EmailGroup, Jira, ServiceNow, Slack, SMS, Teams } from '../Components';
import { actions } from '../slice';

import { getLabel, getResourceIcon, renderNavTitle } from './utils';

export const useIntegrations = () => {
  const dispatch = useDispatch();
  const { accounts } = useSelector(selectSetup);
  const [actionType, setActionType] = useState<FormAction>('Add');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [currentRecord, setCurrentRecord] = useState<Record<string, any>>({});
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenRediscoverModal,
    onClose: onCloseRediscoverModal,
    onOpen: onOpenRediscoverModal,
  } = useDisclosure();
  const deleteClient = useDisclosure();

  const getAccounts = useCallback(
    (accountType: AccountType) => {
      dispatch(
        setupActions.getAccounts({
          q: {
            page: accounts.page.info.page_number,
            size: accounts.page.info.page_size,
            accountType: accountType,
          },
          onSuccess: res => {
            !!res?.total &&
              dispatch(
                sharedStateActions.getSnapshotStatus({
                  q: {
                    getLatestWorkflowRequest: {
                      account_ids: map(res?.items, o => o.uuid),
                    },
                  },
                }),
              );
          },
        }),
      );
    },
    [dispatch, accounts.page.info],
  );

  const onConfirmDeleteClient = useCallback(
    (uuid, clientName) => {
      dispatch(
        actions.deleteClient({
          q: { uuid },
          onSuccess: () => {
            dispatch(actions.loadClients({ clientName }));
            deleteClient.onClose();
            setCurrentRecord({});
          },
        }),
      );
    },
    [dispatch, deleteClient],
  );
  const onConfirmDeleteAccount = useCallback(
    (uuid: string, accountType: AccountType) => {
      dispatch(
        actions.deleteAccount({
          q: { uuid },
          onSuccess: () => {
            getAccounts(accountType);
            appActions.loadEnvAndAccounts();
          },
        }),
      );
    },
    [dispatch, getAccounts],
  );

  const onEditProvider = useCallback(
    (provider: Record<string, any>) => {
      setActionType('Update');
      onOpen();
      setSelectedProvider(provider?.cloud_type);
      setCurrentRecord(provider);
    },
    [onOpen],
  );

  const onClickRediscoverInventory = useCallback(
    (provider: Record<string, any>) => {
      onOpenRediscoverModal();
      setCurrentRecord(provider);
    },
    [onOpenRediscoverModal],
  );

  const onConfirmRediscoverInventory = useCallback(
    (accountType: AccountType) => {
      dispatch(
        actions.rediscoverInventory({
          q: { uuid: currentRecord.uuid },
          onSuccess: () => {
            onCloseRediscoverModal();
            getAccounts(accountType);
          },
        }),
      );
    },
    [dispatch, currentRecord, onCloseRediscoverModal, getAccounts],
  );

  const onEdit = useCallback(
    (row: ClientResponse) => {
      setActionType('Update');
      onOpen();
      setCurrentRecord(row);
      row?.client_name === Clients.Elasticsearch &&
        setSelectedProvider(Clients.Elasticsearch);
    },
    [onOpen],
  );

  const updateAccount = useCallback(
    (
      payload: CloudAccountApiUpdateCloudAccountRequest,
      accountType: AccountType,
    ) => {
      dispatch(
        actions.updateAccount({
          q: payload,
          onSuccess: () => {
            getAccounts(accountType);
            onClose();
          },
        }),
      );
    },
    [dispatch, onClose, getAccounts],
  );

  const onboardAccount = useCallback(
    (
      payload: CloudAccountApiOnboardCloudAccountRequest,
      accountType: AccountType,
    ) => {
      dispatch(
        setupActions.onboardAccount({
          q: payload,
          onSuccess: () => {
            getAccounts(accountType);
            onClose();
          },
        }),
      );
    },
    [dispatch, onClose, getAccounts],
  );

  const onAddClient = useCallback(() => {
    onOpen();
    setActionType('Add');
    dispatch(actions.resetActiveClient());
  }, [onOpen, dispatch]);

  const onClickAddProvider = useCallback(
    (value: string) => {
      setSelectedProvider(value);
      onOpen();
      setActionType('Add');
    },
    [onOpen],
  );

  const renderContentHeader = useCallback(
    (type: string, iconFilled?: boolean) => {
      return (
        <PageHeaderWithIcon
          label={(isOpen ? actionType + ' ' : '') + getLabel(type)}
          iconType={type}
          iconFilled={iconFilled}
          reversed={isOpen}
        />
      );
    },
    [actionType, isOpen],
  );

  const identityAndAccessOptions = useMemo(
    () => [
      /*{
        key: 'microsoftAD',
        label: 'Microsoft Active Directory',
        icon: <MicrosoftActiveDirectoryIcon />,
        onClick: () => onClickAddProvider('microsoftAD'),
      },*/
      {
        key: CloudProviders.AzureAd,
        label: 'Azure Active Directory',
        icon: getResourceIcon(CloudProviders.AzureAd),
        onClick: () => onClickAddProvider(CloudProviders.AzureAd),
      },
      {
        key: CloudProviders.Okta,
        label: 'Okta',
        icon: getResourceIcon(CloudProviders.Okta),
        onClick: () => onClickAddProvider(CloudProviders.Okta),
      },
    ],
    [onClickAddProvider],
  );

  const siemOptions = useMemo(
    () => [
      {
        key: Clients.Elasticsearch,
        label: 'Elastic For SIEM',
        icon: getResourceIcon(Clients.Elasticsearch),
        onClick: () => onClickAddProvider(Clients.Elasticsearch),
      },
    ],
    [onClickAddProvider],
  );

  const dataSourceOptions = useMemo(
    () => [
      {
        key: CloudProviders.Snowflake,
        label: 'Snowflake',
        icon: getResourceIcon(CloudProviders.Snowflake),
        onClick: () => onClickAddProvider(CloudProviders.Snowflake),
      },
      {
        key: CloudProviders.Gitlab,
        label: 'GitLab',
        icon: getResourceIcon(CloudProviders.Gitlab),
        onClick: () => onClickAddProvider(CloudProviders.Gitlab),
      },
      {
        key: CloudProviders.Github,
        label: 'GitHub',
        icon: getResourceIcon(CloudProviders.Github),
        onClick: () => onClickAddProvider(CloudProviders.Github),
      },
      {
        key: 'BitBucket',
        label: 'Atlassian BitBucket',
        icon: getResourceIcon(CloudProviders.Bitbucket),
        onClick: () => onClickAddProvider(CloudProviders.Bitbucket),
      },
    ],
    [onClickAddProvider],
  );

  const integrationsMenuItems: SideNavMenuItemProps[] = useMemo(
    () => [
      {
        title: 'Identity & Access',
        key: 'identityAccess',
        icon: getResourceIcon(IconTypes.IamGroup),
        component: <IdentityAndAccess />,
        rightContentHeader: !isOpen && (
          <Menu
            menuItems={identityAndAccessOptions}
            buttonLabel={
              <AddButton label="Add Provider" onClick={onAddClient} />
            }
          />
        ),
        contentHeader: isOpen
          ? renderContentHeader(selectedProvider, false)
          : renderContentHeader(IconTypes.IdentityAndAccess),
      },
      {
        title: 'SaaS',
        key: IconTypes.Saas,
        icon: getResourceIcon(IconTypes.EbsSnapshot),
        component: <DataSources />,
        rightContentHeader: !isOpen && (
          <Menu
            menuItems={dataSourceOptions}
            buttonLabel={<AddButton label="Add SaaS" onClick={onAddClient} />}
          />
        ),
        contentHeader: isOpen
          ? renderContentHeader(selectedProvider, false)
          : renderContentHeader(IconTypes.Saas, true),
      },
      {
        title: 'Ticketing / ITSM',
        key: 'ticketing',
        icon: getResourceIcon(IconTypes.Ticket),
        secondary: [
          {
            title: renderNavTitle(IconTypes.Servicenow),
            key: 'serviceNow',
            component: <ServiceNow />,
            contentHeader: renderContentHeader(IconTypes.Servicenow, false),
            rightContentHeader: !isOpen && (
              <AddButton label="Add Client" onClick={onAddClient} />
            ),
          },
          {
            title: renderNavTitle(IconTypes.Jira),
            key: 'jira',
            component: <Jira />,
            contentHeader: renderContentHeader(IconTypes.Jira, false),
            rightContentHeader: !isOpen && (
              <AddButton label="Add Project" onClick={onAddClient} />
            ),
          },
        ],
      },
      {
        title: 'Notification',
        key: 'notification',
        icon: getResourceIcon(IconTypes.Notification),
        secondary: [
          {
            title: renderNavTitle(IconTypes.Slack),
            key: 'slack',
            component: <Slack />,
            contentHeader: renderContentHeader(IconTypes.Slack, false),
            rightContentHeader: !isOpen && (
              <AddButton label="Add Channel" onClick={onAddClient} />
            ),
          },
          {
            title: renderNavTitle(IconTypes.Teams),
            key: 'teams',
            component: <Teams />,
            contentHeader: renderContentHeader(IconTypes.Teams, false),
            rightContentHeader: !isOpen && (
              <AddButton label="Add Channel" onClick={onAddClient} />
            ),
          },
          {
            title: renderNavTitle(IconTypes.Email),
            key: 'email_group',
            component: <EmailGroup />,
            contentHeader: renderContentHeader(IconTypes.Email),
            rightContentHeader: !isOpen && (
              <AddButton label="Add Group" onClick={onAddClient} />
            ),
          },
          {
            title: renderNavTitle(IconTypes.Sms),
            key: 'sns',
            component: <SMS />,
            contentHeader: renderContentHeader(IconTypes.Sms),
            rightContentHeader: !isOpen && (
              <AddButton label="Add SMS" onClick={onAddClient} />
            ),
          },
        ],
      },
      {
        title: 'Single Sign-On',
        key: IconTypes.Sso,
        component: <SSO />,
        icon: getResourceIcon(IconTypes.Sso),
        contentHeader: renderContentHeader(IconTypes.Sso),
        rightContentHeader: !isOpen && (
          <AddButton label="Add Provider" onClick={onAddClient} />
        ),
      },
      {
        title: 'SIEM',
        key: 'siem',
        icon: getResourceIcon(IconTypes.SIEM),
        rightContentHeader: !isOpen && (
          <Menu
            menuItems={siemOptions}
            buttonLabel={<AddButton label="Add SIEM" onClick={onAddClient} />}
          />
        ),
        contentHeader: isOpen
          ? renderContentHeader(selectedProvider, false)
          : renderContentHeader(IconTypes.SIEM),
        component: <SIEM />,
      },
    ],
    [
      isOpen,
      onAddClient,
      identityAndAccessOptions,
      siemOptions,
      selectedProvider,
      dataSourceOptions,
      renderContentHeader,
    ],
  );

  const onCancel = useCallback(() => {
    setSelectedProvider('');
    setCurrentRecord({});
    onClose();
  }, [onClose]);

  const clientActionsColumn = {
    header: 'Actions',
    accessor: '',
    align: 'center',
    styles: { cell: { overflow: 'visible' } },
    render: ({ row }) => {
      return (
        <Flex justifyContent="center">
          <ActionButton
            label={'Edit'}
            icon={<EditIcon />}
            onClick={() => {
              onEdit(row);
            }}
          />
          <ActionButton
            label={'Delete'}
            icon={<TrashIcon color="red" />}
            onClick={() => {
              deleteClient.onOpen();
              setCurrentRecord(row);
            }}
          />
        </Flex>
      );
    },
  };

  return {
    onConfirmDeleteClient,
    onConfirmDeleteAccount,
    onEdit,
    onEditProvider,
    onAddClient,
    actionType,
    isOpen,
    onCancel,
    setActionType,
    selectedProvider,
    updateAccount,
    onboardAccount,
    integrationsMenuItems,
    onClickAddProvider,
    currentRecord,
    onClickRediscoverInventory,
    onConfirmRediscoverInventory,
    onCloseRediscoverModal,
    isOpenRediscoverModal,
    getAccounts,
    isOpenDeleteClientModal: deleteClient.isOpen,
    onCloseDeleteClientModal: deleteClient.onClose,
    clientActionsColumn,
    setCurrentRecord,
  };
};
