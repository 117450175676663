import React, { FC, Suspense } from 'react';

import { Routes, Route } from 'react-router-dom';

import { FilterRouter } from 'components/FilterView';
import { NotFoundPage } from 'components/NotFoundPage';
import { Compliance } from 'containers/Compliance/Loadable';
import { ConversationalAI } from 'containers/ConversationalAI';
import { Dashboard } from 'containers/Dashboard/Loadable';
import { EventLog } from 'containers/EventLog';
import { Findings } from 'containers/Findings/Loadable';
import { Inventory } from 'containers/Inventory/Loadable';
import { Meta } from 'containers/Meta';
import { Organizations } from 'containers/Organizations';
import { PolicyHub } from 'containers/PolicyHub/Loadable';
import { Reports } from 'containers/Reports/Loadable';
import { Settings } from 'containers/Settings/Loadable';
import { Setup } from 'containers/Setup/Loadable';
import { UserProfile } from 'containers/UserProfile/Loadable';
import { Visibility } from 'containers/Visibility/Loadable';
import { PageLoader } from 'utils/PageLoader';

interface IAppRoutes {}

export const AppRoutes: FC<IAppRoutes> = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route element={<FilterRouter path="/" />}>
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route element={<FilterRouter path="/dashboard" />}>
          <Route path="/dashboard/*" element={<Dashboard />} />
        </Route>
        <Route element={<FilterRouter path="/findings" />}>
          <Route path="/findings/*" element={<Findings />} />
        </Route>
        <Route element={<FilterRouter path="/visibility" />}>
          <Route path="/visibility/*" element={<Visibility />} />
        </Route>
        <Route element={<FilterRouter path="/inventory" />}>
          <Route path="/inventory/*" element={<Inventory />} />
        </Route>
        <Route element={<FilterRouter path="/policy-hub" />}>
          <Route path="/policy-hub/*" element={<PolicyHub />} />
        </Route>
        <Route element={<FilterRouter path="/compliance" />}>
          <Route path="/compliance/*" element={<Compliance />} />
        </Route>
        <Route element={<FilterRouter path="/reports" />}>
          <Route path="/reports/*" element={<Reports />} />
        </Route>
        <Route element={<FilterRouter path="/setup" />}>
          <Route path="/setup/*" element={<Setup />} />
        </Route>
        <Route element={<FilterRouter path="/organizations" />}>
          <Route path="/organizations/*" element={<Organizations />} />
        </Route>
        <Route element={<FilterRouter path="/settings/:type" />}>
          <Route path="/settings/:type" element={<Settings />} />
        </Route>
        <Route element={<FilterRouter path="/user-profile" />}>
          <Route path="/user-profile" element={<UserProfile />} />
        </Route>
        <Route element={<FilterRouter path="/event-log" />}>
          <Route path="/event-log" element={<EventLog />} />
        </Route>
        <Route element={<FilterRouter path="/meta" />}>
          <Route path="/meta" element={<Meta />} />
        </Route>
        <Route element={<FilterRouter path="/home" />}>
          <Route path="/home/*" element={<ConversationalAI />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};
