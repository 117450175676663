import React, { FC } from 'react';

import { Accordion, Grid, GridItem } from '@chakra-ui/react';

import { CustomTable2 as Table } from 'components/DataDisplay/NewTable/Table';
import { MetaAccordionItem } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetaAccordionItem';
import { MetadataCommonFields } from 'containers/ActiveCloudResource/Components/ResourceMetadataDrawer/Components/MetadataCommonFields';
import { useActiveResourceContext } from 'containers/ActiveCloudResource/context/context';

import {
  DNSName,
  Instances,
  Scheme,
  SecurityGroups,
  SourceSecurityGroup,
  SubnetIDs,
} from '../../../Components/MetadataField/MetaGridFields';

export const ElasticLoadBalancerMetadata: FC = () => {
  const { resourceDetails } = useActiveResourceContext();
  const { data: resource } = resourceDetails;

  const listenerColumns = [
    { header: 'Instance port', accessor: 'Listener.InstancePort' },
    { header: 'LB port', accessor: 'Listener.LoadBalancerPort' },
    { header: 'Protocol', accessor: 'Listener.Protocol' },
    { header: 'Policy', accessor: 'PolicyNames', align: 'left' },
  ];
  const healthCheckColumns = [
    {
      header: 'Threshold',
      accessor: 'HealthyThresholdCount',
      render: ({ row }) => row?.HealthyThresholdCount ?? row?.HealthyThreshold,
    },
    {
      header: 'Interval (sec)',
      accessor: 'HealthCheckIntervalSeconds',
      render: ({ row }) => row?.HealthCheckIntervalSeconds ?? row?.Interval,
    },
    { header: 'Target', accessor: 'Target', align: 'left' },
    {
      header: 'Timeout (sec)',
      accessor: 'HealthCheckTimeoutSeconds',
      render: ({ row }) => row?.HealthCheckTimeoutSeconds ?? row?.Timeout,
    },
    {
      header: 'Unhealthy threshold',
      accessor: 'UnhealthyThresholdCount',
      render: ({ row }) =>
        row?.UnhealthyThresholdCount ?? row?.UnhealthyThreshold,
    },
  ];

  const listener =
    resource?.network?.Listeners ??
    resource?.config?.ListenerDescriptions ??
    [];
  const healthCheck =
    resource?.network?.TargetGroups ?? [resource?.config?.HealthCheck] ?? [];

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      <MetadataCommonFields />
      {DNSName(resource)}
      {Scheme(resource)}
      {SubnetIDs(resource)}
      {Instances(resource)}
      {SecurityGroups(resource)}
      {SourceSecurityGroup(resource)}
      <GridItem colSpan={4}>
        <Accordion allowMultiple>
          <MetaAccordionItem
            panel={<Table columns={listenerColumns} data={listener} />}
            iconType={''}
            label="Listener"
            totalCount={listener?.length ?? 0}
            height="300px"
          />
          <MetaAccordionItem
            panel={<Table columns={healthCheckColumns} data={healthCheck} />}
            iconType={''}
            label="Health check"
            totalCount={healthCheck?.length ?? 0}
            height="150px"
          />
        </Accordion>
      </GridItem>
    </Grid>
  );
};
