/**
 *
 * Top Right Nav Bar Tabs
 *
 */
//import { FaMoon } from 'react-blackIcons/fa';
//import { FiSun } from 'react-blackIcons/fi';
import React from 'react';

import { Box, Flex, IconButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { CustomTooltip } from 'components/DataDisplay/Tooltip/CustomTooltip';
import { SearchBox } from 'components/DataEntry/Input/SearchBox';
import { FilterComponent } from 'components/FilterView';
import { BellIcon, EventLogIcon, ServicesIcon } from 'components/Icons';
import { useNavBarContext } from 'components/Navigation/NavBar/context';

import { Notification } from '../Notification';
import { iconStyles } from '../styles';

import HelpMenu from './Help';
import ProfileMenu from './ProfileMenu';
import { SelectCloudEnvironmentOrAccount } from './SelectCloudEnvironmentOrAccount';

export const RightComponent = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const { search, showWelcomeScreen, cloudInsight } = useNavBarContext();

  /*const { toggleColorMode } = useColorMode();
  const text = useColorModeValue('light', 'dark');
  const SwitchIcon = useColorModeValue(FaMoon, FiSun);*/

  const aiSearchMapping = {
    '/inventory/summary': false,
    '/findings': true,
    '/findings/overview': true,
    '/findings/tickets': false,
    '/findings/alerts': true,
    '/visibility/data': false,
    '/visibility/vulnerability': false,
  };

  const size = useWindowSize();

  return (
    <>
      {!showWelcomeScreen && (
        <>
          <FilterComponent includePaths={Object.keys(aiSearchMapping)}>
            <Box w={size.width < 1360 ? '150px' : '250px'}>
              <SearchBox
                onChange={search.onChangeSearchTerm}
                onSearch={(...args) => {
                  aiSearchMapping[pathname!]
                    ? search.onAiSearch(...args)
                    : search.onSearch(...args);
                }}
                value={search.value}
                onClickClose={search.onClearSearch}
                isLoading={search.isLoading}
                navBar
                aiSearchModal={search?.aiSearchModal}
              />
            </Box>
          </FilterComponent>
          <Box flex={1} pl={1}>
            <Box>
              <SelectCloudEnvironmentOrAccount />
            </Box>
          </Box>

          {/* <Search /> */}
          <FilterComponent filterPath={`/alerts`}>
            <Flex pos={'relative'}>
              <CustomTooltip label={'Show alerts'} placement={'bottom'}>
                <Notification />
              </CustomTooltip>
            </Flex>
          </FilterComponent>
          <Flex pos={'relative'}>
            <CustomTooltip label={'Org settings'} placement={'bottom'}>
              <Box pos={'relative'}>
                <IconButton
                  aria-label="Organization Settings"
                  icon={<ServicesIcon width="17px" />}
                  {...iconStyles}
                  p={1.5}
                  onClick={() => navigate('/settings/organization')}
                />
              </Box>
            </CustomTooltip>
          </Flex>
          {/*<IconButton
        aria-label={`${text} mode`}
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        {...iconStyles}
      />*/}

          {/*   <!-- Profile dropdown --> */}
          <FilterComponent filterPath={`/event-log`}>
            <CustomTooltip label={'Ariksa event logs'} placement={'bottom'}>
              <Box pos={'relative'}>
                <IconButton
                  aria-label="Event Log"
                  icon={<EventLogIcon />}
                  onClick={() => navigate('/event-log')}
                  {...iconStyles}
                  p={1.5}
                />
              </Box>
            </CustomTooltip>
          </FilterComponent>
          <CustomTooltip label={'Runtime Insights'} placement={'bottom'}>
            <Box pos={'relative'}>
              <IconButton
                aria-label="Cloud Insights"
                icon={<BellIcon />}
                onClick={() => cloudInsight.onOpen()}
                {...iconStyles}
                p={1.5}
              />
            </Box>
          </CustomTooltip>
          <HelpMenu />
        </>
      )}
      <ProfileMenu />
    </>
  );
};
