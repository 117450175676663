import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { useDisclosure } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { CriticalDataIcon, IconTypes } from 'components/Icons';
import { selectDataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { IdentityCards } from 'containers/Dashboard/utils/IdentityCards';

export const InsightsForDataSources: React.FC = () => {
  const { insightsForDataSources } = useSelector(selectDataSecurityOverview);
  const { exposedAndUnencrypted, inactiveAndAnomaly } = insightsForDataSources;
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <IdentityCards
        label="Insights for data sources"
        icon={<CriticalDataIcon />}
        iconBgColor="critical"
        items={[
          {
            label: 'Exposed',
            count:
              exposedAndUnencrypted.data?.internet_accessible
                ?.total_resources || 0,
            isLoading: exposedAndUnencrypted.isLoading,
            iconType: IconTypes.Globe,
            bg: '#f8dddd',
            iconColor: 'critical',
            iconTooltip: 'Publicly exposed data sources',
            iconTooltipW: 32,
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                iconType: IconTypes.Globe,
                panel: PanelsEnum.PubliclyExposedDataSources,
                total:
                  exposedAndUnencrypted.data?.internet_accessible
                    ?.total_resources || 0,
              });
            },
          },
          {
            label: 'Unencrypted',
            count:
              exposedAndUnencrypted.data?.unencrypted?.total_resources || 0,
            isLoading: exposedAndUnencrypted.isLoading,
            iconType: IconTypes.UnencryptedDisk,
            bg: '#d3e0fc',
            iconColor: 'critical',
            iconTooltip: 'Unencrypted data sources',
            iconTooltipW: 32,
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.UnencryptedDataSources,
                iconType: IconTypes.UnencryptedDisk,
                total:
                  exposedAndUnencrypted.data?.unencrypted?.total_resources || 0,
              });
            },
          },
          {
            label: 'Unused',
            count: exposedAndUnencrypted.data?.inactive?.total_resources || 0,
            isLoading: exposedAndUnencrypted.isLoading,
            iconType: IconTypes.UNUSED_ENTITIES,
            bg: '#fcf1d3',
            iconColor: 'critical',
            iconTooltip: 'Data sources that are not active',
            iconTooltipW: 32,
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.InactiveDataSources,
                iconType: IconTypes.UNUSED_ENTITIES,
                total:
                  exposedAndUnencrypted.data?.inactive?.total_resources || 0,
              });
            },
          },
          {
            label: 'Anomaly',
            count: inactiveAndAnomaly.data?.length || 0,
            isLoading: inactiveAndAnomaly.isLoading,
            iconType: IconTypes.AnomalousBehaviour,
            bg: '#d2edfd',
            iconColor: 'critical',
            iconTooltip: 'Data sources with anomalous activity or changes',
            iconTooltipW: 32,
            onClick() {
              allEntitiesDisclosure.onOpen();
              setDetails({
                panel: PanelsEnum.AnomalousDataSources,
                iconType: IconTypes.AnomalousBehaviour,
                total: inactiveAndAnomaly.data?.length || 0,
              });
            },
          },
        ]}
        cardH="11rem"
        cardW="9rem"
        iconBoxSize={10}
        labelFontSize="md"
        contentSpacing={4}
        countFontSize="xl"
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
