import React, { FC, useEffect, useState } from 'react';

import { PackageCategory } from '@ariksa/inventory-core';
import { NativeResources } from '@ariksa/inventory-core/api';
import { Box, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Select } from 'components/DataEntry';
import {
  applicationOptions,
  exploitableOptions,
  libraryOptions,
  osOptions,
  packageTypeOptions,
  patchAvailableOptions,
  vulnerabilitySeverityOptions,
} from 'containers/Visibility/Vulnerabilities/Components/utils';
import { selectVulnerabilities } from 'containers/Visibility/Vulnerabilities/selectors';

interface Props {
  isLoading: boolean;
  isPatchable: Record<string, any>;

  updateValue(field: string, value: any);

  isExploitable?: Record<string, any>;

  packageType?: Record<string, any>;

  packageName?: Record<string, any>;

  severity: Record<string, any>;

  showPackageType?: boolean;
}

export const VulnerabilityFiltersMenu: FC<Props> = props => {
  const { selectedTab } = useSelector(selectVulnerabilities);
  const {
    isPatchable,
    packageType,
    packageName,
    severity = '',
    isLoading = false,
    isExploitable,
    updateValue,
    showPackageType = true,
  } = props;

  const [showPackageName, setShowPackageName] = useState(!!packageType?.value);
  const [packageNameOptions, setPackageNameOptions] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    const options =
      packageType?.value === PackageCategory.OsPackage
        ? osOptions
        : packageType?.value === PackageCategory.ApplicationPackage
        ? applicationOptions
        : packageType?.value === PackageCategory.LibraryPackage
        ? libraryOptions
        : [];
    setPackageNameOptions(options);
    updateValue?.('packageName', options[0]);
  }, [packageType, updateValue]);

  return (
    <HStack>
      {selectedTab !== 'cve' ? (
        <>
          <Box w={48} zIndex={900}>
            <Select
              options={patchAvailableOptions}
              value={isPatchable}
              onChange={s => updateValue('isPatchable', s)}
              showIconInValueContainer
              leftMessage="Patchable:"
              isDisabled={isLoading}
              menuPortalTarget={document.body}
            />
          </Box>
          {showPackageType &&
            selectedTab !== NativeResources.ElasticContainerRegistry && (
              <Box w={52} zIndex={900}>
                <Select
                  options={packageTypeOptions(selectedTab)}
                  value={packageType}
                  onChange={s => {
                    updateValue?.('packageType', s);
                    setShowPackageName(!!s.value);
                  }}
                  showIconInValueContainer
                  leftMessage="Package type:"
                  isDisabled={isLoading}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}
          {/*{showPackageName &&
            selectedTab !== NativeResources.ElasticContainerRegistry && (
              <Box w={48} zIndex={900}>
                <Select
                  options={packageNameOptions}
                  value={packageName}
                  onChange={s => updateValue('packageName', s)}
                  showIconInValueContainer
                  //styles={styles}
                  isDisabled={isLoading}
                  menuPortalTarget={document.body}
                />
              </Box>
            )}*/}
        </>
      ) : (
        <Box w={48} zIndex={900}>
          <Select
            options={exploitableOptions}
            value={isExploitable}
            onChange={s => updateValue('isExploitable', s)}
            showIconInValueContainer
            leftMessage="Exploitable:"
            isDisabled={isLoading}
            menuPortalTarget={document.body}
          />
        </Box>
      )}
      <Box w={48} zIndex={900}>
        <Select
          options={vulnerabilitySeverityOptions}
          value={severity}
          onChange={s => updateValue('severity', s)}
          showIconInValueContainer
          //styles={styles}
          isDisabled={isLoading}
          menuPortalTarget={document.body}
        />
      </Box>
      {/*<MenuListFilter menuItems={menuItems} />*/}
    </HStack>
  );
};
